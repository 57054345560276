<template>
  <v-menu
    v-model="show"
    offset-y
    content-class="main-menu"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on: { click } }">
      <v-btn
        color="#1E88E5"
        elevation="0"
        class="btn-menu"
        @click="click"
      >
        <span class="font-weight-bold">{{ menuName }}</span> <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list
      flat
      class="pa-5 pl-10 pr-0 menu-list"
    >
      <v-subheader
        v-if="menuTitle"
      >
        <h3>{{ menuTitle }}</h3>
      </v-subheader>
      <div
        v-for="item in menuList"
        :key="item.id"
      >
        <v-list-item
          v-if="hasSubMenu(item)"
          :class="['menu-item', selectedMenuId === item.id ? 'selected' : '']"
          @click.stop="showSubMenu(item)"
        >
          <v-list-item-title>
            {{ item.name }}
            <v-icon class="float-right">
              mdi-chevron-right
            </v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else
          :key="item.id"
          route
          :to="getMenuUrl(item)"
          class="menu-item"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
    <v-list
      v-show="showedSubMenu"
      class="sub-menu pa-5 pl-10"
    >
      <v-list-item
        v-for="subMenuItem in subMenu"
        :key="subMenuItem.id"
        route
        :to="getMenuUrl(subMenuItem)"
        class="menu-item"
      >
        <v-list-item-title>{{ subMenuItem.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { MenuType } from "../../constants/const";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["menuName", "menuList", "menuTitle", "menuType"],
  data() {
    return {
      subMenu: [],
      showedSubMenu: false,
      selectedMenuId: 0,
      show: false
    };
  },
  computed: {},
  watch: {
    show: function(val) {
      if (!val) {
        this.reset();
      }
    }
  },
  methods: {
    showSubMenu: function(item) {
      if (item.id === this.selectedMenuId || this.selectedMenuId === 0) {
        this.showedSubMenu = !this.showedSubMenu;
      }
      if (this.showedSubMenu) {
        this.selectedMenuId = item.id;
        this.subMenu = item.subCategories;
      } else {
        this.selectedMenuId = 0;
        this.subMenu = [];
      }
    },
    reset: function() {
      this.subMenu = [];
      this.showedSubMenu = false;
      this.selectedMenuId = 0;
    },
    getMenuUrl: function(item) {
      if (this.menuType === MenuType.CATEGORY) {
        return "/category/" + item.id;
      } else if (this.menuType === MenuType.ROOM) {
        return "/room/" + item.id;
      }
    },
    hasSubMenu: function (item) {
      return item.subCategories && item.subCategories.length !== 0
    },

  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  font-weight: 600;
  font-size: 14px;
}
.v-list.sub-menu {
  width: 30%;
  min-width: 200px;
  height: 100%;
  border: solid 1px #0864b2;
  position: absolute;
  top: 0;
  left: 300px;
  background-color: #0864b2;
  .v-list-item.menu-item {
    color: #ffffff !important;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1200px) {
  .v-list.sub-menu {
    left: 30%;
  }
}

.main-menu {
  width: 100%;
  border: none;
  .menu-list {
    min-height: 350px;
    min-width: 300px;
    width: 30%;
    border: solid 1px #e8e8e8;
    border-right-width: 0;
    .v-list-item.menu-item {
      font-weight: bold;
      &.selected {
        color: #ffffff !important;
        background-color: #0864b2;
      }
    }
  }
}
</style>
