import {ProductService} from "../services/api-service";
import Vue from "vue";
import {isBlank} from "../../utils/common-utils"

async function prepareProductThumbnal(products) {
  return await products.map(product => {
    if (product.productItemThumbnails && product.productItemThumbnails.length > 0) {
      product.thumbnailUrl = product.productItemThumbnails[0].uri;
    }
    return product;
  });
}

const initProduct = {
  name: "",
  description: "",
  price: 0,
  onRequest: false,
  thumbnails: [],
  specifications: [],
  categoryDetailsProducts: [],
  topProducts: []
};

const state = {
  product: {
    name: "",
    description: "",
    price: 0,
    onRequest: false,
    thumbnails: [],
    specifications: [],
  },
  products: [],
  categoryDetailsProducts: [],
  fetchProductError: false,
  topProducts: [],
  aproducts: [],
  roomDetailsProductsData: {},
  relativeProductsData: {}
};

const mutations = {
  resetProductState(state) {
    state.product = _.cloneDeep(initProduct);
  },
  resetCategoryDetailsProducts(state) {
    Vue.set(state, 'categoryDetailsProducts', []);
  },
  updateProduct(state, product) {
    state.product = product;
  },
  updateProducts(state, products) {
    Vue.set(state, 'products', products);
  },
  updateFetchProductError(state, fetchError) {
    state.fetchProductError = fetchError;
  },
  async updateTopProducts(state, topProducts) {
    Vue.set(state, 'topProducts', topProducts);
  },
  async updateCategoryDetailsProducts(state, products) {
    let productItems = await prepareProductThumbnal(products);
    Vue.set(state, 'categoryDetailsProducts', productItems);
  },
  async updateRoomDetailsProductsData(state, data) {
    let productsData = {
      topProducts: await prepareProductThumbnal(data.topProducts),
      newProducts: await prepareProductThumbnal(data.newProducts)
    };
    Vue.set(state, 'roomDetailsProductsData', productsData);
  },
  async updateRelativeProductsData(state, data) {
    let productsData = {
      similarProducts: await prepareProductThumbnal(data.similarProducts),
      inRoomProducts: await prepareProductThumbnal(data.inRoomProducts)
    };
    Vue.set(state, 'relativeProductsData', productsData);
  },
};

const actions = {
  createProduct({state}) {
    return ProductService.create(state.product);
  },
  updateProduct({state}) {
    return ProductService.update(state.product);
  },
  updateProducts(context, products) {
    context.commit("updateProducts", products);
  },
  resetProductState(context) {
    context.commit("resetProductState");
  },
  updateProductState(context, product) {
    context.commit("updateProduct", product);
  },
  fetchProducts(context) {
    return ProductService.findAll().then(({data}) => {
      context.commit("updateFetchProductError", false);
      context.commit("updateProducts", data);
    }).catch(error => {
      context.commit("updateFetchProductError", true);
      context.commit("updateProducts", []);
    })
  },
  async fetchProduct(context, id) {
    await ProductService.findById(id).then(res => {
      context.commit("updateFetchProductError", false);
      context.commit("updateProduct", res.data);
    }).catch(error => {
      context.commit("updateFetchProductError", true);
      context.commit("resetProductState");
      console.log(error);
    });
  },
  async fetchCategoryDetailsProducts(context, categoryId) {
    await ProductService.findByCategoryId(categoryId).then(res => {
      context.commit("updateCategoryDetailsProducts", res.data);
    }).catch(error => {
      console.log(error);
      context.commit("resetCategoryDetailsProducts");
    })
  },
  resetCategoryDetailsProducts(context) {
    context.commit("resetCategoryDetailsProducts", []);
  },
  async setTopProduct(context, data) {
    await ProductService.setTopProduct(data.productId, data.topProduct);
  },
  resetTopProducts(context) {
    context.commit("updateTopProducts", []);
  },
  async fetchTopProducts(context) {
    await ProductService.findTopProducts().then(res => {
      context.commit("updateTopProducts", res.data);
    }).catch(error => {
      console.log(error);
      context.commit("updateTopProducts", []);
    })
  },
  fetchSearchProducts(context, name) {
    ProductService.searchProductByNameInPage(name).then(res => {
      context.commit("updateProducts", res.data)
    }).catch(error => {
      console.log(error);
      context.commit("updateProducts", [])
    })
  },
  async fetchRoomDetailProducts(context, roomId) {
    await ProductService.findByRoomId(roomId).then(res => {
      context.commit("updateRoomDetailsProductsData", res.data);
    }).catch(error => {
      console.log(error);
      context.commit("updateRoomDetailsProductsData", {});
    })
  },
  async fetchRelativeProducts(context, id) {
    await ProductService.getRelativeProducts(id).then(res => {
      context.commit("updateRelativeProductsData", res.data);
    }).catch(error => {
      console.log(error);
      context.commit("updateRelativeProductsData", {});
    })
  }
};

const getters = {
  fetchProductError: state => {
    return state.fetchProductError;
  },
  product: state => {
    return state.product;
  },
  products: state => {
    return state.products.map(product => {
      return {
        ...product,
        categoryName: product.category ? product.category.name : "Không danh mục"
      }
    });
  },
  categoryDetailsProducts: state => {
    return state.categoryDetailsProducts;
  },
  productSpecifications: state => {
    if (state.product && state.product.specifications) {
      return state.product.specifications.filter(specification =>
        !isBlank(specification.productItemSpecifications.content)
      ).map(specification => {
        return {
          label: specification.label,
          content: specification.productItemSpecifications.content
        }
      })
    }
    return [];
  },
  productThumbnails: state => {
    if (state.product && state.product.productItemThumbnails) {
      return state.product.productItemThumbnails.map(thumbnail => thumbnail.uri);
    }
    return [];
  },
  topProducts: state => {
    return state.topProducts;
  },
  roomDetailsProductsData: state => {
    return state.roomDetailsProductsData;
  },
  relativeProductsData: state => {
    return state.relativeProductsData;
  }
};

export const product = {
  state,
  mutations,
  actions,
  getters
};
