<template>
  <div class="idea-item">
    <a :href="`/ideas/${ideaItem.id}`">
      <div class="idea-thumbnail w-100" :style="{ 'background-image': 'url(' + ideaItem.thumbnail + ')' }">
      </div>
      <div class="idea-info">
        <div class="title">{{ ideaItem.title }}</div>
        <div class="date-created">{{ ideaItem.createdAt }}</div>
      </div>
      <p class="preview-ctn">
        {{ ideaItem.description }}
      </p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    ideaItem: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.idea-item {
  .idea-thumbnail {
    width: 100%;
    padding-top: 60%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  width: 550px;
  margin: 0 6px;
  * {
    color: #000000;
  }
  a {
    display: inline;
    width: auto;
  }
  p {
    white-space: normal;
  }
  .desc-img {
    height: 300px;
  }
  .idea-info {
    margin-top: 10px;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .date-created {
      font-style: italic;
      font-size: 12px;
      font-weight: 400;
      color: #727a82;
    }
  }

  .preview-ctn {
    width: 100%;
    margin-top: 10px;
    font-weight: 300;
  }
}
</style>
