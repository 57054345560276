<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Danh sách khách hàng
      </h1>
    </div>

    <!-- DATATABLE -->
    <div class="customers-table">
      <div class="input-search">
        <a-input-search
          v-model="query" class="float-right"
          placeholder="Nhập tên hoặc số điện thoại"
          style="width: 400px"
          @search="onSearch"
          @change="isEmpty"
        />
      </div>
      <a-table
        :columns="columns" :data-source="customers"
        :row-key="customer => customer.id"
        size="large"
        :loading="loading"
        :pagination="pagination"
        @change="handleChange"
      >
        <span
          slot="customerName" slot-scope="text, customer"
          class="d-flex align-items-center"
        >
          <router-link :to="`customers/${customer.id}`">{{ customer.name }}</router-link>
        </span>
        <span slot="customerPhone" slot-scope="text, customer">
          {{ customer.phoneNumber }}
        </span>
        <span slot="customerStatus" slot-scope="text, customer">
          <a-tag v-if="customer.status === 1" color="#ACE539">
            <div class="d-flex align-items-center">
              <a-icon type="check-circle" class="mr-1" /> Đã tư vấn
            </div>
          </a-tag>
          <a-tag v-if="customer.status === 0" color="#D9AF00">
            <div class="d-flex align-items-center">
              <a-icon type="exclamation-circle" class="mr-1" /> Đang chờ tư vấn
            </div>
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, customer">
          <a-dropdown placement="bottomLeft" :trigger="['click']">
            <a-button
              shape="circle" icon="ellipsis"
              class="d-flex align-items-center justify-content-center"
            />
            <a-menu slot="overlay">
              <a-menu-item @click="showConfirm(customer)">
                Xóa
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {CustomerService} from "../../../store/services/api-service";

export default {
  name: "CustomerOverview",
  data() {
    return {
      loading: false,
      filteredInfo: null,
      sortedInfo: null,
      query: "",
      pagination: {}
    }
  },
  computed: {
    ...mapGetters([
        'customers',
        'customerTotal'
    ]),
    columns() {
      return [
        {
          title: 'Tên đầy đủ',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'customerName'},
          width: '30%',
        },
        {
          title: 'Số điện thoại',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
          scopedSlots: {customRender: 'customerPhone'},
          width: '20%',
        },
        {
          title: 'Trạng thái',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: {customRender: 'customerStatus'},
          width: '10%',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: '10%'
        }
      ];
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page=1, query=""){
      this.loading = true;
      await this.$store.dispatch("fetchCustomers", {page:page, query:query});
      this.pagination = {
          total: this.customerTotal || 0,
          pageSize: 10,
      };
      this.loading = false;
    },
    async loadMore(){
      let self = this;
      self.loading = true;
      try {
          await self.fetchData(self.pagination.current, self.query);
          self.loading = false;
      } catch (e) {
          console.log(e);
          self.loading = false;
      }
    },
    handleChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.loadMore();
    },
    async onSearch() {
      let self = this;
      self.loading = true;
      try {
          await self.fetchData(self.pagination.current, self.query);
          self.loading = false;
      } catch (e) {
          console.log(e);
          self.loading = false;
      }
    },
    isEmpty() {
      let self = this;
      if (this.query === '') {
          self.fetchData();
      }
    },
    async deleteCustomer(customer) {
      let self = this;
      self.loading = true;
      try{
          await CustomerService.deleteById(customer.id);
          await self.fetchData();
          self.$message.success('Xóa sản phẩm thành công!');
      }catch (e) {
          self.$message.error('Có lỗi xảy ra!' + e.message);
          console.log(e)
      }
      self.loading = false;
    },
    showConfirm: function (customer) {
      const self = this;
      this.$confirm({
        title: 'Bạn có chắc chắn xóa khách hàng này?',
        okType: 'danger',
        onOk() {
          self.deleteCustomer(customer)
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .anticon {
    vertical-align: 0 !important;
  }
  .input-search {
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 25px;
  }
</style>