<template>
  <v-app>
    <app-header></app-header>
    <main class="h-100">
      <router-view></router-view>
    </main>
    <div class="flex-1"></div>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import Header from "../../components/main-page/HomeHeader";
import Footer from "../../components/main-page/HomeFooter";
import {ActionTypes} from "../../constants/action-types";

export default {
  name: "MainPage",
  components: {
    appHeader: Header,
    appFooter: Footer
  },
  created() {
    this.$store.dispatch(ActionTypes.LOAD_MENU_CATEGORIES);
    this.$store.dispatch(ActionTypes.LOAD_ROOMS);
  }
};
</script>

<style scoped></style>
