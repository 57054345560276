import { render, staticRenderFns } from "./RoomOverview.vue?vue&type=template&id=1ea54840&scoped=true&"
import script from "./RoomOverview.vue?vue&type=script&lang=js&"
export * from "./RoomOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ea54840",
  null
  
)

export default component.exports