<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="main-container">
        <div v-if="!fetchProductError">
          <!-- BEGIN PRODUCTS BASIC INFO -->
          <div class="product-details-section">
            <div class="row w-100 m-0">
              <!-- BEGIN PRODUCTS THUMBNAILS -->
              <div class="col-12 col-md-7">
                <app-product-thumbnails-slider :thumbnails="productThumbnails"></app-product-thumbnails-slider>
              </div>
              <!-- BEGIN PRODUCTS THUMBNAILS -->

              <!-- BEGIN PRODUCTS INFO -->
              <div class="product--basic-info col-12 col-md-5 pt-2">
                <h2 class="nuvola-text-uppercase font-weight-bold">
                  {{ product.name }}
                </h2>
                <span class="product-detail--description nuvola-font-size-16">
                  {{ product.description }}
                </span>
                <div class="mt-2">
                  <span class="nuvola-font-size-30 font-weight-bold text--black">
                    {{ product.onRequest ? "Liên hệ" : formatCurrency(product.price) }}
                  </span>
                </div>
                <a v-scroll-to="'#product--specifications'" href="">Xem thêm thông tin sản phẩm</a>
                <app-product-contact-form-modal :product="product"></app-product-contact-form-modal>
              </div>
              <!-- BEGIN PRODUCTS INFO -->
            </div>
          </div>
          <a-divider></a-divider>
          <!-- END PRODUCTS BASIC INFO -->
        </div>

        <div v-if="fetchProductError">
          <a-empty></a-empty>
        </div>

        <!-- BEGIN SIMILAR PRODUCTS -->
        <div class="m-auto p-4 hide">
          <h3 class="text-center">
            Sản phẩm tương tự
          </h3>
          <app-products-slider :product-items="relativeProductsData.similarProducts"></app-products-slider>
        </div>
        <a-divider class="mb-0"></a-divider>
        <!-- END SIMILAR PRODUCTS -->

        <!-- BEGIN PRODUCT SPECIFICATIONS -->
        <div
          v-if="productSpecifications && productSpecifications.length > 0"
          id="product--specifications" class="nuvola--mb-32 p-4"
        >
          <h3 class="text-center">
            Thông tin chi tiết sản phẩm
          </h3>
          <template v-for="(specification, index) in productSpecifications">
            <app-product-specification :key="index" :specification="specification"></app-product-specification>
          </template>
        </div>
        <!-- BEGIN PRODUCT SPECIFICATIONS -->

        <!-- BEGIN SIMILAR PRODUCTS -->
        <div
          class=" m-auto p-4 "
        >
          <h3 class="text-center">
            Có thể bạn thích
          </h3>
          <app-products-slider :product-items="relativeProductsData.inRoomProducts"></app-products-slider>
        </div>
        <!-- END SIMILAR PRODUCTS -->
      </div>
    </div>
  </div>
</template>

<script>
import ProductThumbnailsSlider from "../../components/main-page/ProductThumbnailsSlider"
import ProductsSlider from "../../components/main-page/ProductsSlider"
import ProductSpecification from "../../components/main-page/ProductSpecification"
import ProductContactFormModal from "../../components/main-page/ProductContactFormModal.vue"
import {formatCurrency} from "../../utils/common-utils"
import {mapGetters} from "vuex";
import {store} from "@/store";

async function initData(to, next) {
  if (to.params.slug !== undefined) {
    await store.dispatch(
      "fetchProduct",
      to.params.slug
    );
    await store.dispatch(
      "fetchRelativeProducts",
      to.params.slug
    );
  }
  return next();
}

export default {
  name: "ProductDetails",
  async beforeRouteUpdate(to, from, next) {
    return await initData(to, next);
  },
  async beforeRouteEnter(to, from, next) {
    return await initData(to, next);
  },
  components: {
    appProductThumbnailsSlider: ProductThumbnailsSlider,
    appProductsSlider: ProductsSlider,
    appProductSpecification: ProductSpecification,
    appProductContactFormModal: ProductContactFormModal
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(
      [
        "product",
        "loadCategories",
        "specifications",
        "productSpecifications",
        "productThumbnails",
        "fetchProductError",
        "relativeProductsData"
      ]
    )
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    },
  }
}
</script>

<style lang="scss">
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-top: 0 !important;
  }

  .product--basic-info {
    @media screen and (min-width: 768px) {
      padding-left: 2.5rem !important;
    }
  }

  .product--order-button {
    .ant-btn {
      background-color: #ffbb00;
      color: black;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        font-weight: bold;
      }

      &:hover, &:active, &:focus {
        color: black;
        background-color: #ffd023;
      }
    }
  }
</style>