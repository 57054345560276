<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <router-link to="/admin/categories">
        <a-button
          type="primary" shape="circle"
          icon="left"
          size="small" class="d-flex justify-content-center mr-3"
        />
      </router-link>
      <h1 style="font-size: 1.5rem" class="mb-0">
        {{ pageHeader }}
      </h1>
    </div>
    <div>
      <a-form :form="categoryForm" @submit="handleSubmit">
        <a-form-item
          v-bind="formItemLayout" label="Tên danh mục"
        >
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    validator: checkNameExisted,
                  },
                  {
                    required: true,
                    message: 'Vui lòng nhập tên danh mục sản phẩm!'
                  }
                ],
              },
            ]"
            placeholder="Tên danh mục sản phẩm!"
          />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout" label="Mô tả danh mục"
        >
          <a-textarea
            v-decorator="[
              'description'
            ]"
            placeholder="Mô tả danh mục"
            :rows="4"
          />
        </a-form-item>
        <a-form-item
          class="mb-2" label="Ảnh mô tả"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          required="true"
        >
          <a-upload
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUploadThumbnail"
            @change="handleChangeThumbnail"
          >
            <img
              v-if="categoryThumbnail" :src="imageUrl"
              alt="thumbnail" class="category-thumbnail"
            >
            <img
              v-if="!categoryThumbnail && category.thumbnail" :src="category.thumbnail"
              alt="thumbnail" class="category-thumbnail"
            >
            <div v-if="!categoryThumbnail && !category.thumbnail">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          label="Danh mục cha"
        >
          <a-select
            v-decorator="[
              'parentCategoryId',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng chọn danh mục cha!',
                  },
                ],
              },
            ]"
            style="width: 300px"
          >
            <a-select-option :value="0">
              Danh mục chính
            </a-select-option>
            <a-select-option v-for="category in loadCategories" :key="category.id">
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button
            type="primary"
            @click="handleSubmit"
          >
            Lưu danh mục
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "../../../constants/action-types";
import {CategoriesPath} from "../../../constants/path";
import ApiService, {CategoriesService, UploadService} from "../../../store/services/api-service";
import {store} from "@/store";
import {isBlank, replaceAll} from "../../../utils/common-utils";
import {BucketType} from "../../../constants/const";
const VUE_APP_API_URL = process.env.VUE_APP_API_URL || "";

function getImgSource(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

let timer = null;
let isEdit = false;
let originName = "";

export default {
  name: "CreateCategory",
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch(ActionTypes.RESET_CATEGORY_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(ActionTypes.RESET_CATEGORY_STATE);
    if (to.params.slug !== undefined) {
      isEdit = true;
      await store.dispatch(
        "fetchCategory",
        to.params.slug
      );
      originName = store.getters.category.name;
    } else {
      isEdit = false;
      originName = ""
    }
    return next();
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 5},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      },
      loading: false,
      imageUrl: '',
      categoryThumbnail: null
    }
  },
  computed: {
    ...mapGetters(
      [
        "category",
        "categoryThumbnailFile",
        "loadCategories",
        "fetchError"
      ]
    ),
    pageHeader() {
      return isEdit ? "Cập nhật danh mục sản phẩm" : "Tạo danh mục sản phẩm"
    }
  },
  beforeCreate() {
    this.categoryForm = this.$form.createForm(this, {
      name: 'categoryForm',
    });
  },
  mounted() {
    if (isEdit && this.fetchError) {
      this.$message.error(`Có lỗi xảy ra!`);
      this.$router.push("/admin/categories");
    }
    this.categoryForm.setFieldsValue({
      name: this.category.name,
      description: this.category.description,
      parentCategoryId: this.category.parentCategoryId
    });
    this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    saveCategory: function (categoryPayload) {
      this.$store.dispatch("updateCategoryState", categoryPayload);
      const action = isEdit ? "updateCategory" : "createCategory";
      this.$store.dispatch(action).then(() => {
        this.$router.push("/admin/categories");
      }).catch((error) => {
        this.$message.error(`Có lỗi xảy ra! ${error}`);
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.categoryForm.validateFields((err, values) => {
        if (err) {
          return;
        }
        if (!this.category.thumbnail && !this.categoryThumbnail) {
          this.$message.error('Vui lòng đăng ảnh mô tả!');
          return;
        }
        let categoryPayload = {
          ...this.category,
          ...values,
          thumbnail: this.category.thumbnail
        };
        if (!this.categoryThumbnail) {
          this.saveCategory(categoryPayload);
          return;
        }
        UploadService.uploadSingle(BucketType.CATEGORY, this.categoryThumbnail).then(res => {
          this.saveCategory({
            ...categoryPayload,
            thumbnail: res.data.path
          });
        }).catch(error => {
          this.$message.error(error);
          console.log(error);
        })
      });
    },
    handleChangeThumbnail(info) {
      const file = info.file;
      const isValidImageType = (file.type === 'image/jpeg') || (file.type === 'image/png');
      if (!isValidImageType) {
        this.$message.error('Chỉ có thể upload file JPG hoặc PNG!');
        return;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('Dung lượng ảnh không quá 5MB!');
        return;
      }
      if (isValidImageType && isLt5M) {
        this.categoryThumbnail = file;
        getImgSource(file, imageUrl => {
          this.imageUrl = imageUrl;
        });
      }
    },
    beforeUploadThumbnail() {
      return false;
    },
    checkNameExisted: (rule, value, callback) => {
      if (isBlank(value) || (isEdit && originName === value)) {
        callback();
      }
      return new Promise(function (resolve) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(function () {
          CategoriesService.checkNameExisted({
            name: value
          }).then(function (res) {
            resolve(!res.data.nameExisted);
          }).catch(function (error) {
            resolve(false);
          });
        }, 1000);
      }).then((result) => {
        if (result) {
          callback();
        } else {
          callback('Tên danh mục đã được sử dụng!');
        }
      }).catch((error) => {
        this.$message.error(`Có lỗi xảy ra! ${error}`);
      });
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch(ActionTypes.RESET_CATEGORY_STATE);
    next();
  }
}
</script>

<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .category-thumbnail {
    width: 300px;
    height: auto;
  }
</style>