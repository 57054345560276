export const ActionTypes = {
  LOGIN: "login",
  LOGOUT: "logout",
  UPDATE_USER: "updateUser",
  CHECK_AUTH: "checkAuth",
  FETCH_PROFILE: "fetchProfile",
  LOAD_MENU_CATEGORIES: "loadMenuCategories",
  LOAD_CATEGORIES: "loadCategories",
  LOAD_ROOMS: "loadRooms",
  RESET_CATEGORY_STATE: "resetCategoryState",
  LOAD_TOP_CATEGORIES: "leadTopCategories"
};