<template>
  <div>
    <h1 style="font-size: 1.5rem; margin-bottom: 30px">
      Danh mục loại sản phẩm
    </h1>
    <div class="w-100 d-flex justify-content-end mb-4">
      <a-button
        icon="plus" type="primary"
        class="d-flex align-items-center"
        @click="navigateToCreateCategory"
      >
        Thêm loại sản phẩm
      </a-button>
    </div>

    <a-table
      :columns="columns" :data-source="loadCategories"
      :row-key="category => category.id"
      size="middle"
      :loading="loading"
    >
      <span slot="parentCategoryId" slot-scope="parentCategory">
        {{ (parentCategory === null || parentCategory === 0) ? 'Main category' : getCategoryNameById(parentCategory) }}
      </span>
      <span slot="action" slot-scope="text, category">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a-button
            shape="circle" icon="ellipsis"
            class="d-flex align-items-center justify-content-center"
          />
          <a-menu slot="overlay">
            <a-menu-item @click="navigateToEdit(category.id)">
              Edit
            </a-menu-item>
            <a-menu-item @click="showConfirm(category.id)">
              Delete
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
  </div>
</template>

<script>
  import {ActionTypes} from "../../../constants/action-types";
  import {mapGetters} from "vuex";
  import {CategoriesService} from "../../../store/services/api-service";

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '40%'
    },
    {
      title: 'Danh mục cha',
      dataIndex: 'parentCategoryId',
      key: 'parentCategoryId',
      scopedSlots: { customRender: 'parentCategoryId' },
      width: '40%'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: '20%'
    }
  ];
  export default {
    name: "CategoryOverview",
    data() {
        return {
          categories: [],
          columns,
          loading: false
        }
    },
    computed: {
      ...mapGetters(["loadCategories"])
    },
    async mounted() {
      this.loading = true;
      await this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
      this.loading = false;
    },
    methods: {
      navigateToEdit(id) {
        this.$router.push({
          name: "categoryEdit",
          params : {
            slug: id
          }
        });
      },
      navigateToCreateCategory() {
        this.$router.push("create-category");
      },
      getCategoryNameById(id) {
        const filteredCategories = this.loadCategories.filter(category => category.id === id);
        if (filteredCategories.length > 0) {
          return filteredCategories[0].name;
        }
        return 'No name';
      },
      deleteCategory(id) {
        CategoriesService.deleteById(id).then(() => {
          this.$store.dispatch("loadCategories");
        }).catch(error => {
          console.log(error);
        });
      },
      showConfirm: function(id) {
        const that = this;
        this.$confirm({
          title: 'Bạn có chắc chắn xóa danh mục này?',
          okType: 'danger',
          onOk() {
            that.deleteCategory(id)
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>