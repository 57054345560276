<template>
  <div class="idea-detail-wrapper">
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <div class="d-flex align-items-center mr-auto">
        <router-link to="/admin/ideas">
          <a-button
            type="primary" shape="circle"
            icon="left"
            size="small" class="d-flex justify-content-center mr-3"
          />
        </router-link>
        <h1 style="font-size: 1.5rem" class="mb-0">
          Chi tiết bài viết
        </h1>
      </div>
    </div>
    <!-- END HEADER -->
    <!-- BODY -->
    <div>
      <a-form :form="ideaForm" @submit="handleSubmit">
        <a-form-item label="Tiêu đề bài viết">
          <a-input
            v-decorator="[ 'title', {
              rules: [{
                required: true,
                message: 'Vui lòng nhập tiêu đề!'
              }],
            }]"
            placeholder="Tiêu đề bài viết!"
            class="mw-1000px"
          />
        </a-form-item>
        <a-form-item label="Mô tả ngắn" extra="Mô tả sẽ xuất hiện trên thẻ bài viết.">
          <a-textarea
            v-decorator="[ 'description' ]"
            placeholder="Mô tả ngắn"
            :rows="2"
            class="mw-1000px"
          />
        </a-form-item>
        <div class="ant-form-item-label">
          <label for="span-hidden-2" class="ant-form-item-required">Ảnh mô tả</label>
          <span id="span-hidden-2"></span>
        </div>
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUploadThumbnail"
          @change="handleChangeThumbnail"
        >
          <img
            v-if="ideaThumbnail" :src="imageUrl"
            alt="thumbnail" class="category-thumbnail"
          >
          <img
            v-if="!ideaThumbnail && idea.thumbnail" :src="idea.thumbnail"
            alt="thumbnail" class="category-thumbnail"
          >
          <div v-if="!ideaThumbnail && !idea.thumbnail">
            <a-icon :type="uploadThumbnailLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
        <a-form-item label="Các loại sản phẩm">
          <a-select
            v-decorator="['categoryIds', {
              rules: [{
                required: true,
                message: 'Vui lòng chọn loại sản phẩm!'
              }],
            }]"
            class="w-100 mw-1000px"
            mode="multiple"
            style="width: 200px"
            placeholder="Vui lòng chọn các loại sản phẩm !"
          >
            <a-select-option v-for="category in loadCategories" :key="category.id">
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <div class="ant-form-item-label">
          <label for="span-hidden1">Nội dung bài viết</label>
          <span id="span-hidden1"></span>
        </div>
        <div>
          <a-button size="small" @click="showModal">
            <a-icon type="picture" />
            Thêm ảnh từ URL
          </a-button>
          <a-upload
            name="ideaImage"
            :show-upload-list="false"
            :before-upload="beforeUploadThumbnail"
            @change="handleChangeIdeaImage"
          >
            <a-button
              size="small" class="ml-1"
            >
              <a-icon type="cloud-upload" />
              Thêm ảnh từ máy tính
            </a-button>
          </a-upload>
        </div>
        <tinymce
          id="id1" ref="tm"
          v-model="idea.content" :other_options="options"
        ></tinymce>

        <a-form-item>
          <a-button
            type="primary"
            :loading="saving"
            @click="handleSubmit"
          >
            Lưu bài viết
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <!-- END BODY -->
    <a-modal
      v-model="visible"
      @ok="handleOk"
    >
      <span>Đường dẫn tới ảnh</span>
      <a-input v-model="imageUrl" placeholder="Link URL"></a-input>
    </a-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {store} from "@/store";
import {UploadService} from "../../../store/services/api-service";
import {isBlank} from "../../../utils/common-utils";
import {BucketType} from "../../../constants/const";
import {ActionTypes} from "../../../constants/action-types";
const VUE_APP_API_URL = process.env.VUE_APP_API_URL || "";
const env = process.env.NODE_ENV || "";

function getImgSource(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
let isEdit = false;
export default {
  name: "IdeaDetail",
  data() {
    return {
      saving: false,
      visible: false,
      // Use to insert image to editor by URL
      imageUrl: '',
      // Use to save thumbnail file
      ideaThumbnail: null,
      uploadThumbnailLoading: false,
      options: {
        toolbar2: "undo redo | image ",
        content_css : 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
        content_css_cors: true,
        content_style: "img {max-width: 100%;} body {font-family: 'Roboto', sans-serif;}",
        plugins: [
          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'template paste textcolor colorpicker textpattern toc help emoticons hr codesample'
        ]
      }
    }
  },
  computed: {
    ...mapGetters(
      [
        "idea",
        "loadCategories"
      ]
    ),
  },
  beforeCreate() {
    this.ideaForm = this.$form.createForm(this, {
      name: 'ideaForm',
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch("resetIdea");
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("resetIdea");
    if (to.params.slug !== undefined) {
      isEdit = true;
      await store.dispatch(
        "fetchIdea",
        to.params.slug
      );
      if (store.getters.ideaFetchError) {
        next({name: "ideas"})
      }
    } else {
      isEdit = false;
    }
    return next();
  },
  mounted() {
    this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
    this.ideaForm.setFieldsValue({
      categoryIds: this.idea.categoryIds,
      title: this.idea.title,
      description: this.idea.description,
    });
  },
  beforeUpdate() {
    this.idea.content = this.idea.content + " ";
  },
  methods: {
    saveIdea(ideaPayload) {
      console.log('save idea payload', ideaPayload)
      this.$store.dispatch("setIdea", ideaPayload);
      this.$store.dispatch("saveIdea").then(() => {
        this.$router.push("/admin/ideas");
      }).catch((error) => {
        this.$message.error(`Có lỗi xảy ra! ${error}`);
      }).finally(() => {
        this.saving = false;
      });
    },
    handleSubmit(e) {
      this.saving = true;
      e.preventDefault();
      this.ideaForm.validateFields((err, values) => {
        if (err) {
          this.saving = false;
          return;
        }
        let blankThumbnail = !this.ideaThumbnail && isBlank(this.idea.thumbnail);
        if (blankThumbnail) {
          this.$message.error('Vui lòng đăng ảnh mô tả!');
          this.saving = false;
          return;
        }
        let ideaPayload = {
          ...this.idea,
          ...values,
        };
        console.log('ideaPayload', ideaPayload)
        if (this.ideaThumbnail) {
          UploadService.uploadSingle(BucketType.IDEA, this.ideaThumbnail).then(async res => {
            let saveObj = {
              ...ideaPayload,
              thumbnail: res.data.path
            };
            console.log('saveObj', saveObj);
            this.saveIdea(saveObj);
          }).catch(error => {
            this.$message.error(error);
            console.log(error);
          }).finally(() => {
            this.saving = false;
          })
        } else {
            let thumbnail = this.idea.thumbnail;
            console.log('ideaPayload saveObj categoryIds', ideaPayload.categoryIds);
            let array = new Array(ideaPayload.categoryIds);
            console.log('array', array)
             let saveObj = {
              ...ideaPayload,
              categoryIds: array,
              thumbnail: thumbnail
            };
            Object.
            console.log('saveObj categoryIds', saveObj.categoryIds);
            console.log('saveObj', saveObj);
            this.saveIdea(saveObj);
        }
      });
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      if (!isBlank(this.imageUrl)) {
        let contentToInsert = `<img src="${this.imageUrl}" alt=""  />`;
        this.insertContent(contentToInsert);
      }
      this.imageUrl = '';
      this.visible = false;
    },
    beforeUploadThumbnail() {
      return false;
    },
    validateImage: function (info) {
      const file = info.file;
      const isValidImageType = (file.type === 'image/jpeg') || (file.type === 'image/png');
      if (!isValidImageType) {
        this.$message.error('You can only upload JPG or PNG file!');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('Image must smaller than 5MB!');
      }
      if (isValidImageType && isLt5M) {
        return file
      } else {
        return null;
      }
    },
    handleChangeThumbnail(info) {
      const file = this.validateImage(info);
      if (file) {
        this.ideaThumbnail = file;
        getImgSource(file, imageUrl => {
          this.imageUrl = imageUrl;
        });
      }
    },
    handleChangeIdeaImage(info) {
      const file = this.validateImage(info);
      if (file) {
        UploadService.uploadSingle(BucketType.IDEA_CONTENT, file).then(res => {
          let prePath = env === "development" ? VUE_APP_API_URL : "";
          let newImage = `<img src="${prePath}${res.data.path}" alt=""  />`;
          this.insertContent(newImage);
        }).catch(error => {
          console.log(error);
          this.$message.error('Có lỗi xảy ra!');
        })
      }
    },
    insertContent(content) {
      this.$refs.tm.editor.execCommand('mceInsertContent', false, content);
    }
  },
}
</script>

<style lang="scss">
  .mw-1000px {
    max-width: 1000px !important;
  }
  .idea-detail-wrapper {

    .ant-form-item {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 0 !important;
      font-weight: bold;
    }

    #id1_ifr {
      min-height: 600px;
      height: 50vh !important;
      html {
        body {
          font-family: "Arial",serif;
        }
      }
    }
  }
</style>