<template>
  <div>
    <div v-if="ideas.length > 0" class="idea-index-slider">
      <slick
        ref="slick"
        :options="slickOptions"
      >
        <div
          v-for="item in ideas" :key="item.id"
          class="d-flex justify-content-center"
        >
          <app-idea-item :idea-item="item" />
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
  import IdeaItem from "./IdeaItem";
  import Slick from 'vue-slick';
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';

  export default {
    components: {
      appIdeaItem: IdeaItem,
      Slick
    },
    props: {
      ideas: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        slickOptions: {
          //options can be used from the plugin documentation
          infinite: true,
          accessibility: true,
          adaptiveHeight: false,
          arrows: true,
          dots: true,
          edgeFriction: 0.30,
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }
      };
    },
    beforeUpdate() {
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
      }
    },
    updated() {
      this.$nextTick(function () {
        if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
        }
      });
    },
    methods: {
      next() {
        this.$refs.slick.next();
      },
    }
  };
</script>

<style lang="scss">
    .idea-index-slider {
        .slick-prev:before, .slick-next:before {
            color: black;
        }

        .slick-dots li button:before {
            font-size: 12px;
        }
    }
</style>
