<template>
  <div class="header-ctn">
    <div
      class="d-flex justify-content-center align-items-center m-auto header-1 px-2"
    >
      <!-- BEGIN LOGO -->
      <div class="branch-logo-ctn">
        <router-link to="/">
          <img
            src="@/assets/images/logo/height-100.png" alt="Nuvola"
            class="branch-logo"
          >
        </router-link>
      </div>
      <!-- END LOGO -->

      <!-- BEGIN SEARCH BOX -->
      <div class="search-box">
        <a-auto-complete
          v-model="searchValue"
          class="global-search"
          size="large"
          style="width: 100%"
          option-label-prop="text"
          :default-active-first-option="false"
          @select="onSelect"
          @search="handleSearch"
          @keyup.enter="navigateToSearchProductPage()"
          @blur="onBlurSearchProduct()"
          @focus="searchInNavibar = true"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="(item, index) in dataSource" :key="index.toString()"
              :text="item.name" class="abc"
            >
              <div class="p-2">
                <span class="nuvola-font-size-16 d-flex align-items-center">
                  <a-spin v-if="item.id === -1" class="mr-3" />
                  <a-icon
                    v-if="item.id === -2" type="close"
                    class="mr-3"
                  />
                  {{ item.name }}
                </span>
              </div>
            </a-select-option>
          </template>
          <a-input
            placeholder="Tìm kiếm sản phẩm" size="large"
            @keyup.enter="navigateToSearchProductPage()"
          >
            <a-icon
              slot="prefix" type="search"
              @click="navigateToSearchProductPage()"
            />
          </a-input>
        </a-auto-complete>
      </div>
      <!-- END SEARCH BOX -->
    </div>

    <!-- BEGIN NAVI-BAR -->
    <v-layout
      wrap
      class="menu-ctn"
    >
      <div class="toolbar-wrapper">
        <div class="nav-drawer">
          <div class="d-flex align-items-center h-100 w-100">
            <a-button
              type="primary" shape="circle"
              icon="bars" class="d-flex align-items-center justify-content-center shadow-none border-0"
              :size="'large'" @click="showDrawer"
            />
            <a-drawer
              title="Menu"
              placement="right"
              :closable="true"
              :visible="visible"
              width="90%"
              :body-style="{'padding':0}"
              @close="onClose"
            >
              <a-menu
                mode="inline"
              >
                <a-menu-item
                  key="home" class="d-flex align-items-center"
                  @click="navigateTo('')"
                >
                  <a-icon type="home" />
                  <span>Trang chủ</span>
                </a-menu-item>
                <a-sub-menu key="products">
                  <span slot="title" class="d-flex align-items-center"><a-icon type="mail" /><span>Sản phẩm</span></span>
                  <template v-for="(category, index) in categories">
                    <a-menu-item
                      v-if="category.subCategories.length === 0"
                      :key="index"
                      @click="navigateTo('category', category.id)"
                    >
                      {{ category.name }}
                    </a-menu-item>
                    <a-sub-menu
                      v-if="category.subCategories.length > 0" :key="index"
                      :title="category.name"
                    >
                      <a-menu-item
                        v-for="(subCategory, subCategoryIndex) in category.subCategories" :key="subCategoryIndex"
                        @click="navigateTo('category', subCategory.id)"
                      >
                        {{ subCategory.name }}
                      </a-menu-item>
                    </a-sub-menu>
                  </template>
                </a-sub-menu>
                <a-sub-menu key="rooms">
                  <span slot="title" class="d-flex align-items-center"><a-icon type="appstore" /><span>Loại phòng</span></span>
                  <a-menu-item
                    v-for="(room, index) in rooms" :key="`rooms-${index}`"
                    @click="navigateTo('room', room.id)"
                  >
                    {{ room.name }}
                  </a-menu-item>
                </a-sub-menu>
                <a-menu-item
                  key="ideas" class="d-flex align-items-center"
                  @click="navigateTo('ideas')"
                >
                  <a-icon type="pie-chart" />
                  <span>Ý tưởng</span>
                </a-menu-item>
                <a-menu-item
                  key="contact" class="d-flex align-items-center"
                  @click="navigateTo('about')"
                >
                  <a-icon type="pie-chart" />
                  <span>Liên hệ</span>
                </a-menu-item>
              </a-menu>
            </a-drawer>
          </div>
        </div>
        <v-toolbar
          class="menu"
          flat
          dark
        >
          <v-toolbar-items class="menu-item">
            <app-menu-list
              menu-name="Sản Phẩm"
              :menu-list="categories"
              menu-title="Sản phẩm"
              :menu-type="MenuType.CATEGORY"
            ></app-menu-list>
          </v-toolbar-items>
          <v-toolbar-items class="menu-item">
            <app-menu-list
              menu-name="Loại Phòng"
              :menu-list="rooms"
              menu-title="Các loại phòng"
              :menu-type="MenuType.ROOM"
            >
            </app-menu-list>
          </v-toolbar-items>
          <v-toolbar-items class="menu-item">
            <v-btn
              color="#1E88E5"
              elevation="0"
              route
              to="/ideas"
            >
              <span class="font-weight-bold">Ý tưởng</span>
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items class="menu-item">
            <v-btn
              color="#1E88E5"
              elevation="0"
              route
              to="/about"
            >
              <span class="font-weight-bold">Liên Hệ</span>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>
    </v-layout>
    <!-- END NAVI-BAR -->
  </div>
</template>

<script>
import MenuList from "./MenuList";
import {MenuType} from "../../constants/const";
import {ProductService} from "../../store/services/api-service";
import _ from "lodash";
import {isBlank} from "../../utils/common-utils"
import {product} from "../../store/modules/product";

export default {
  components: {
    appMenuList: MenuList
  },
  data() {
    return {
      MenuType: MenuType,
      visible: false,
      rootSubmenuKeys: ['home', 'products', 'rooms', 'contact'],
      openKeys: ['products'],
      dataSource: [],
      searchValue: "",
      searchInNavibar: true
    };
  },
  computed: {
    categories() {
      return this.$store.getters.loadCategoryMenu;
    },
    rooms() {
      return this.$store.getters.loadRooms;
    }
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    async navigateTo(path, slug) {
      let loader = this.$loading.show();
      if (slug) {
        await this.$router.push(`/${path}/${slug}`).catch(error => {});
      } else {
        await this.$router.push(`/${path}`).catch(error => {});
      }
      this.visible = false;
      loader.hide();
    },
    resetSearchData: function () {
      this.searchValue = "";
      this.dataSource = [];
    },
    async onSelect(value) {
      let selectedProduct = await this.dataSource[value];
      if (selectedProduct.id > 0) {
        await this.navigateTo("product", selectedProduct.id);
      }
      this.resetSearchData();
    },
    handleSearch(value) {
      this.dataSource = [
        {
          name: 'Đang tìm kiếm...',
          id: -1
        }
      ];
      this.searchResult(value);
    },
    searchResult: _.debounce(function(query) {
      if (!query || isBlank(query)) {
        this.dataSource = [];
        return;
      }
      ProductService.searchProductByNameInNavibar(_.trim(query)).then(res => {
        if (res.data && res.data.length > 0) {
          this.dataSource = res.data;
        } else {
          this.dataSource = [{
            name: 'Không tìm thấy sản phẩm nào.',
            id: -2
          }];
        }
      }).catch(() => {
        this.dataSource = [];
      });
    }, 500),
    async navigateToSearchProductPage() {
      if (isBlank(this.searchValue)) {
        return;
      }
      this.searchInNavibar = false;
      await this.navigateTo(`search-product?name=${_.trim(this.searchValue)}`);
      this.resetSearchData();
    },
    onBlurSearchProduct() {
      this.searchInNavibar = false;
      this.dataSource = [];
    }
  }
};
</script>

<style lang="scss" scoped>
  .nav-drawer {
    height: 56px;
    display: block;
    align-items: center;
    padding-left: 1rem;

    button {
      background-color: #1E88E5;
    }
  }

  .header-ctn {
    background-color: #ffffff;

    .header-1 {
      width: 100%;
      height: 130px;

      .search-box {
        display: none;
        position: relative;

        .search-input {
          width: 90%;
          position: absolute;
          top: 50%;
          left: 45%;
          transform: translate(-50%, -50%);
        }
      }

      .branch-logo-ctn {
        width: 100%;
        text-align: center;

        .branch-logo {
          cursor: pointer;
          height: 70px;
          width: auto;
        }
      }
    }

    .menu-ctn {
      background-color: #1E88E5;

      .toolbar-wrapper {
        width: 1200px;
        margin: auto;

        .menu {
          width: 100%;
          background-color: transparent;
          display: none;

          .menu-item {
            .v-btn {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .header-ctn {
      .header-1 {
        width: 1200px;

        .search-box {
          /*width: 80%;*/
        }
      }

      .toolbar-wrapper {
        .menu-ctn {
          .toolbar-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 960px) {
    .header-ctn {
      .header-1 {
        .search-box {
          /*width: 80%;*/
        }

        .branch-logo-ctn {
          /*width: 20%;*/
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .header-ctn {
      .header-1 {
        .search-box {
          display: block;
          width: 80% !important;
        }

        .branch-logo-ctn {
          width: 20% !important;
        }
      }
    }
  }

  @media only screen and (min-width: 576px) {
    .nav-drawer {
      display: none;
    }
    .header-ctn {
      .header-1 {
        .search-box {
          display: block;
          width: 70%;
        }

        .branch-logo-ctn {
          width: 30%;
        }
      }

      .menu-ctn {
        .toolbar-wrapper {
          .menu {
            width: 100%;
            background-color: transparent;
            display: block;

            .menu-item {
              .v-btn {
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
</style>
