import {RoomsService} from "../services/api-service";

const state = {
  room: {
    name: '',
    thumbnail: '',
    description: '',
    categoryIds: []
  },
  rooms: []
};
const mutations = {
  resetRoomState(state) {
    state.room = {
      name: '',
      thumbnail: '',
      description: '',
      categoryIds: [],
      categories: []
    }
  },
  updateRoom(state, room) {
    if (room.categories) {
      room.categoryIds = room.categories.map(category => category.id);
    }
    state.room = room;
  },
  updateRooms(state, rooms) {
    state.rooms = rooms;
  }
};
const actions = {
  resetRoomState(context) {
    context.commit("resetRoomState");
  },
  updateRoomState(context, room) {
    context.commit("updateRoom", room);
  },
  createRoom({state}) {
    return RoomsService.create(state.room);
  },
  loadRooms(context) {
    RoomsService.findAll().then(res => {
      context.commit("updateRooms", res.data);
    }).catch(error => {
      console.log(error);
    })
  },
  async fetchRoom(context, slug) {
    await RoomsService.findById(slug).then(res => {
      context.commit("updateRoom", res.data);
    }).catch(error => {
      console.log(error);
    })
  }
};
const getters = {
  room: state => {
    return state.room;
  },
  rooms: state => {
    return state.rooms;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};