export const Path = {
  LOGIN: "login",
  USERS: "users",
  CATEGORIES: "categories",
  ROOMS: "rooms",
  UPLOAD: "upload",
  SPECIFICATIONS: "specifications",
  PRODUCT: "products",
  OPPORTUNITY: "opportunities",
  ORGANIZATION_SETTINGS: "organization-settings",
  IDEA: "ideas",
  CUSTOMER: "customers"
};

export const UsersPath = {
  CHECK_AUTH: Path.USERS + "/check-auth"
};

export const CategoriesPath = {
  CHECK_NAME_EXISTED: Path.CATEGORIES + "/check-name-existed",
  UPLOAD_CATEGORY_THUMBNAIL: Path.CATEGORIES + "/upload-thumbnail"
};

export const UploadPath = {
  UPLOAD_SINGLE: Path.UPLOAD + "/image",
  UPLOAD_MULTIPLE: Path.UPLOAD + "/images",
};

export const RoomsPath = {
  CHECK_NAME_VALID: Path.ROOMS + "/check-name-valid"
};

export const SpecificationsPath = {
  DEFAULT_SPECIFICATIONS: Path.SPECIFICATIONS + "/default"
};

export const ProductPath = {
  FIND_BY_ID: Path.PRODUCT + "/id",
  FIND_BY_CATEGORY_ID: Path.PRODUCT + "/category",
  SET_TOP_PRODUCT: Path.PRODUCT + "/set-top-product",
  FIND_TOP_PRODUCTS: Path.PRODUCT + "/top-products",
  SEARCH_PRODUCT_NAVIBAR: Path.PRODUCT + "/search-navibar",
  SEARCH_PRODUCT_IN_PAGE: Path.PRODUCT + "/search-page",
  FIND_BY_ROOM_ID: Path.PRODUCT + "/get-by-room",
  GET_RELATIVE_PRODUCTS: Path.PRODUCT + "/get-relative-products",
};

export const OpportunityPath = {
  CREATE_OPPORTUNITY: Path.OPPORTUNITY + "/create",
  FIND_BY_ID: Path.OPPORTUNITY + "/id",
  UPDATE_STATUS: Path.OPPORTUNITY + "/update-status",
  UPDATE_CANCEL: Path.OPPORTUNITY + "/update-cancel",
};

export const IdeaPath = {
  FIND_BY_ID: Path.IDEA + "/id",
  IDEAS_HOMEPAGE: Path.IDEA + "/homepage"
};

export const CustomerPath = {
  GENERAL: Path.CUSTOMER,
  MARK_RESOLVED: Path.CUSTOMER + "/mark-resolved"
};