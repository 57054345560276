import {CategoriesService, IdeaService} from "../services/api-service";
import Vue from "vue";

const state = {
  idea: {
    title: "",
    thumbnail: "",
    content: ""
  },
  ideas: [],
  fetchError: false
};

const mutations = {
  setIdea(state, payload) {
    state.idea = payload;
  },
  setIdeas(state, payload) {
    Vue.set(state, 'ideas', payload);
  },
  setError(state, fetchError) {
    state.fetchError = fetchError;
  }
};

const actions = {
  async fetchIdea(context, id) {
    await IdeaService.findById(id).then(res => {
      context.commit("setError", false);
      context.commit("setIdea", res.data)
    }).catch(() => {
      context.commit("setError", true)
    })
  },
  fetchIdeaList(context) {
    return IdeaService.findAll().then(res => {
      context.commit("setError", false);
      context.commit("setIdeas", res.data)
    }).catch(() => {
      context.commit("setError", true)
    })
  },
  setIdea(context, idea) {
    console.log('setIdea')
    context.commit("setIdea", idea);
  },
  saveIdea(context) {
    console.log('saveIdea')
    console.log('context.state.idea', context.state.idea)
    return IdeaService.save(context.state.idea);
  },
  resetIdea(context) {
    context.commit("setIdea", {
      title: "",
      thumbnail: "",
      content: ""
    })
  },
  async fetchIdeaHomepage(context) {
    await IdeaService.findIdeasHomePage().then(res => {
      context.commit("setError", false);
      context.commit("setIdeas", res.data)
    }).catch(() => {
      context.commit("setError", true)
    })
  }
};

const getters = {
  idea: state => {
    let idea = state.idea;
    if (idea.categories) {
      idea.categoryIds = idea.categories.map(category => category.id);
    }
    return idea
  },
  ideas: state => {
    return state.ideas
  },
  ideaFetchError: state => {
    return state.fetchError
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
