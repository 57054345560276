module.exports = {
  API_URL: process.env.VUE_APP_API_URL,
  IMAGE_PRE_PATH: process.env.IMAGE_PRE_PATH || "",
  MenuType: {
    CATEGORY: 1,
    ROOM: 2
  },
  /**
   * Upload files buckets
   */
  BucketType: {
    CATEGORY: "category",
    ROOM: "room",
    PRODUCT: "product",
    IDEA: "idea",
    IDEA_CONTENT: "idea-content"
  },
  NotificationTypes: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error"
  },
  CONTACT_FORM_INFO_KEY: "contactFormInfo",
  INPUT_MORE_INFO_KEY: "inputMoreInfo",
  TRUE: "true",
  FALSE: "false",
  OPPORTUNITY_STATUSES: {
    NEW: {
      value: 0,
      label: "Mới",
      color: "#ACE539"
    },
    PROCESSING: {
      value: 1,
      label: "Đang xử lý",
      color: "#D9AF00"
    },
    RESOLVED: {
      value: 2,
      label: "Đã tư vấn",
      color: "#4084E8"
    },
    CANCEL: {
      value: 3,
      label: "Hủy",
      color: "#FF5754"
    },
    DEFAULT: {
      label: "Mặc định",
      color: "#8A8A8A"
    }
  },
  DEFAULT_LOGIN_REDIRECT_COMPONENT: "opportunityOverview"
};
