module.exports = {
  MENU_KEYS: {
    DASHBOARD: "dashboard",
    OPPORTUNITY: "opportunity",
    CUSTOMER: "customer",
    ROOM: "room",
    CATEGORY: "category",
    PRODUCT: "product",
    IDEA: "idea",
    SETTINGS: "common-settings"
  }
};