import {OrganizationService} from "../services/api-service"

const state = {
  organizationSettings: null
};

const mutations = {
  setOrganizationSettings(state, settings) {
    state.organizationSettings = settings;
  }
};

const actions = {
  async saveOrganizationSettings({state}) {
    await OrganizationService.save(state.organizationSettings);
  },
  async fetchOrganizationSettings(context) {
    await OrganizationService.getOrganizationSettings().then(res => {
      context.commit("setOrganizationSettings", res.data);
    }).catch(error => {
      console.log(error);
    })
  },
  setOrganizationSettings(context, settings) {
    context.commit("setOrganizationSettings", settings);
  }
};

const getters = {
  organizationSettings: state => {
    return state.organizationSettings;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};