<template>
  <div class="m-auto product-item-card">
    <div>
      <div class="product-thumbnail-wrapper nuvola-cursor-pointer" @click="navigateToProductDetails()">
        <div class="product-thumbnail w-100" :style="{ 'background-image': 'url(' + productItem.thumbnailUrl + ')' }">
        </div>
      </div>
      <div class="products-details">
        <div
          v-show="productItem.isNew"
          class="pa-2 new-icon"
        >
          Mới
        </div>
        <div class="product--name text-truncate nuvola-cursor-pointer" @click="navigateToProductDetails()">
          {{ productItem.name }}
        </div>
        <div class="product--description text-truncate">
          {{ productItem.description }}
        </div>
        <div v-if="!productItem.onRequest" class="product--price">
          {{ formatCurrency(productItem.price) }} đ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "../../utils/common-utils"
import _ from "lodash";

export default {
  name: "ProductItem",
  props: {
    productItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    },
    async navigateToProductDetails() {
      let loader = this.$loading.show();
      this.scrollToTop();
      await this.$router.push(`/product/${this.productItem.id}`);
      loader.hide();
    },
    scrollToTop() {
      this.$scrollTo("#top")
    }
  }
};
</script>

<style lang="scss" scoped>
.product-item-card {
  max-width: 250px;
  width: 100%;
  .products-details {
    padding: 10px;
    .product--name {
      font-weight: bold;
      font-size: 20px;
    }
    .product--description {
      font-style: italic;
    }
    .product--price {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .new-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: bold;
    border-radius: 50%;
    background-color: #7438be;
  }
  .preview-btn {
    width: 60%;
    margin: auto;
    margin-top: 15px;
    padding: 15px;
    border: solid 1px #dadee3;
    border-radius: 3px;
    color: #727a82;
  }
}
  .product-thumbnail {
    width: 100%;
    padding-top: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .product-thumbnail-wrapper {
    padding: 10px
  }
  .product-thumbnail-wrapper:hover .product-thumbnail,
  .product-thumbnail-wrapper:focus .product-thumbnail {
    transform: scale(1.02);
    transition: all .5s;
  }
</style>
