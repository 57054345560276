<template>
  <div class="footer">
    <v-container class="footer-ctn">
      <div class="row w-100 m-0">
        <div class="col-12 col-sm-6 d-flex justify-content-center flex-column">
          <div class="nuvola--mb-32">
            <h5 class="text-white mb-4">
              <a-icon type="pushpin" /> Địa chỉ hệ thống
            </h5>
            <div>
              <div class="d-flex align-items-center">
                <a-icon type="environment" class="mr-2" /><span class="font-weight-bold">NUVOLA Thanh Khê</span>
              </div>
              <div>
                <span>Địa chỉ: 41 Phú Lộc 7, thành phố Đà Nẵng</span>
              </div>
              <div>
                <span class="nuvola-text-underline nuvola-cursor-pointer" @click="navigateToContact()">Tìm chúng tôi trên bản đồ</span>
              </div>
              <div>
                <span>Hotline: +84 886 68 86 52</span>
              </div>
            </div>
          </div>
          <div>
            <h5 class="text-white">
              <a-icon type="pushpin" /> Hotline tư vấn trực tiếp
            </h5>
            <div>
              <a-icon class="nuvola-font-size-32 mr-3" type="phone" />
              <span class="text-white nuvola-font-size-24">0886 68 86 52</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-end justify-content-start flex-column">
          <div class="d-inline-block">
            <span class="nuvola-font-size-30 mr-2">
              <a
                href="https://www.facebook.com/NoiThatGiaDinhNuvola" target="_blank"
                rel="noopener noreferrer"
              ><i class="fab fa-facebook mr-2"></i></a>
            </span>
            <span class="nuvola-font-size-30">
              <a
                href="https://www.facebook.com/NoiThatGiaDinhNuvola" target="_blank"
                rel="noopener noreferrer"
              ><i class="fab fa-instagram mr-2"></i></a>
            </span>
          </div>
          <div class="d-inline-block">
          </div>
        </div>
      </div>
      <div class="footer-info text-center">
        <div class="media-ctn">
        </div>
        <div class="d-flex nuvola--mt-48 col-12">
          <span>&copy; 2022. Nuvola - Nội thất Gia Đình Đà Nẵng</span>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    async navigateToContact() {
      let loader = this.$loading.show();
      this.scrollToTop();
      await this.$router.push("/about").catch(() => {});
      loader.hide();
    },
    scrollToTop() {
      this.$scrollTo("#top")
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #0058a3;
  color: #ffffff !important;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  .v-icon {
    color: #ffffff;
  }
  .footer-ctn {
    .row {
      .category-item {
        .cat_title {
          font-size: 20px;
          font-weight: bold;
          line-height: 32px;
        }
      }
    }
    .footer-info {
      .media-ctn {
        margin-bottom: 30px;
        font-size: 22px;
        a {
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
