import ApiService, {CategoriesService, RoomsService} from "../services/api-service";
import {ActionTypes} from "../../constants/action-types";
import {Path} from "../../constants/path";
import {MutationTypes} from "../../constants/mutation-types";
import Vue from "vue";

const state = {
  category: {
    name: "",
    thumbnail: "",
    description: "",
    parentCategoryId: null
  },
  categories: [],
  rooms: [],
  categoryMenu: [],
  topCategory: [],
  fetchError: false
};

const mutations = {
  setCategories(state, payload) {
    Vue.set(state, 'categories', payload);
  },
  setCategoryMenu(state, payload) {
    Vue.set(state, 'categoryMenu', payload);
  },
  setRooms(state, payload) {
    Vue.set(state, 'rooms', payload);
  },
  setTopCategory(state, payload) {
    Vue.set(state, 'topCategory', payload);
  },
  [MutationTypes.RESET_CATEGORY_STATE](state) {
    state.category = {
      name: "",
      thumbnail: "",
      description: "",
      parentCategoryId: 0
    }
  },
  updateCategory(state, category) {
    state.category = category;
  },
  setError(state, fetchError) {
    state.fetchError = fetchError;
  }
};

const actions = {
  async [ActionTypes.LOAD_MENU_CATEGORIES]({commit}) {
    try {
      const response = await CategoriesService.getMenu();
      commit("setCategoryMenu", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [ActionTypes.LOAD_TOP_CATEGORIES]({commit}){
    try {
      const response = await CategoriesService.getTopCategory();
      commit("setTopCategory", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [ActionTypes.LOAD_ROOMS]({commit}) {
    try {
      const response = await RoomsService.get();
      commit("setRooms", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  [ActionTypes.LOAD_CATEGORIES](context) {
    return ApiService.get(Path.CATEGORIES).then(({data}) => {
      context.commit("setCategories", data);
      return data;
    }).catch((error) => {
      console.log(error);
    })
  },
  [ActionTypes.RESET_CATEGORY_STATE](context) {
    context.commit(MutationTypes.RESET_CATEGORY_STATE);
  },
  createCategory({state}) {
    const category = _.cloneDeep(state.category);
    if (category.parentCategoryId === 0) {
      category.parentCategoryId = null;
    }
    return CategoriesService.create(category);
  },
  updateCategory({state}) {
    const category = _.cloneDeep(state.category);
    if (category.parentCategoryId === 0) {
      category.parentCategoryId = null;
    }
    return CategoriesService.update(category, category.id);
  },
  updateCategoryState(context, category) {
    context.commit("updateCategory", category);
  },
  async fetchCategory(context, slug) {
    await CategoriesService.findById(slug).then(res => {
      context.commit("updateCategory", res.data);
      context.commit("setError", false);
    }).catch(error => {
      console.log(error);
      context.commit("setError", true);
    })
  }
};

const getters = {
  category: state => {
    if (state.category && state.category.parentCategoryId === null) {
      state.category.parentCategoryId = 0;
    }
    return state.category;
  },
  loadCategories: state => {
    return state.categories;
  },
  loadCategoryMenu: state => {
    return state.categoryMenu;
  },
  topCategories: state => {
    return state.topCategory;
  },
  loadCategoryById: state => {
    return cat_id => {
      state.categories.find(cat => {
        return cat.id === cat_id;
      });
    };
  },
  loadRooms: state => {
    return state.rooms;
  },
  loadRoomById: state => {
    return cat_id => {
      state.categories.find(cat => {
        return cat.id === cat_id;
      });
    };
  },
  categoryThumbnailFile: state => {
    return state.categoryThumbnailFile;
  },
  fetchError: state => {
    return state.fetchError;
  }
};

export const category = {
  state,
  mutations,
  actions,
  getters
};
