const {OPPORTUNITY_STATUSES} = require('../constants/const');

module.exports = {
  isEmpty(str) {
    return (!str || 0 === str.length);
  },
  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  },
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  },
  formatCurrency(price) {
    const formatter = new Intl.NumberFormat('en-VN');
    return `${formatter.format(price)} đ`;
  },
  getStatusObject(value) {
    switch (value) {
      case OPPORTUNITY_STATUSES.NEW.value:
        return OPPORTUNITY_STATUSES.NEW;
      case OPPORTUNITY_STATUSES.PROCESSING.value:
        return OPPORTUNITY_STATUSES.PROCESSING;
      case OPPORTUNITY_STATUSES.RESOLVED.value:
        return OPPORTUNITY_STATUSES.RESOLVED;
      default:
        return OPPORTUNITY_STATUSES.DEFAULT;
    }
  }
};