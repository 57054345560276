<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <div class="d-flex align-items-center mr-auto">
        <router-link to="/admin/common-settings">
          <a-button
            type="primary" shape="circle"
            icon="left"
            size="small" class="d-flex justify-content-center mr-3"
          />
        </router-link>
        <h1 style="font-size: 1.5rem" class="mb-0">
          Slider trang chủ
        </h1>
      </div>
    </div>
    <!-- END HEADER -->
    <div class="main-slider-manager">
      <h2>Slider trái</h2>
      <div class="slider-manager-1">
        <div class="row border-bottom">
          <div class="col-1">
            <span class="slider-manager-header-text">Kích hoạt</span>
          </div>
          <div class="col-3">
            <span class="slider-manager-header-text">Hình ảnh</span>
          </div>
          <div class="col-6">
            <span class="slider-manager-header-text">Link URL</span>
          </div>
          <div class="col-1">
            <span class="slider-manager-header-text">Xóa</span>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <a-switch default-checked />
          </div>
          <div class="col-3">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUploadThumbnail"
              @change="handleChangeThumbnail"
            >
              <div v-if="true">
                <a-icon :type="uploadThumbnailLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
          </div>
          <div class="col-6">
            <a-input class="w-100" placeholder="Link URL" />
          </div>
          <div class="col-1">
            <a-icon class="nuvola-cursor-pointer" type="delete" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSliderManager",
  data() {
    return {
      uploadThumbnailLoading: false
    }
  },
  methods: {
    beforeUploadThumbnail() {
      return false;
    },
    validateImage: function (info) {
      const file = info.file;
      const isValidImageType = (file.type === 'image/jpeg') || (file.type === 'image/png');
      if (!isValidImageType) {
        this.$message.error('You can only upload JPG or PNG file!');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('Image must smaller than 5MB!');
      }
      if (isValidImageType && isLt5M) {
        return file
      } else {
        return null;
      }
    },
    handleChangeThumbnail(info) {
      const file = this.validateImage(info);
      if (file) {
        this.ideaThumbnail = file;
        getImgSource(file, imageUrl => {
          this.imageUrl = imageUrl;
        });
      }
    },
    handleChangeIdeaImage(info) {
      const file = this.validateImage(info);
      if (file) {
        UploadService.uploadSingle(BucketType.IDEA_CONTENT, file).then(res => {
          let prePath = env === "development" ? VUE_APP_API_URL : "";
          let newImage = `<img src="${prePath}${res.data.path}" alt=""  />`;
          this.insertContent(newImage);
        }).catch(error => {
          console.log(error);
          this.$message.error('Có lỗi xảy ra!');
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.slider-manager-header-text {
  color: #222;
  line-height: 1.25rem;
  font-weight: bold;
}
</style>