<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container idea-container">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/ideas" class="ant-breadcrumb-link">Ý tưởng</a></a-breadcrumb-item>
        <a-breadcrumb-item>{{ idea.title }}</a-breadcrumb-item>
      </a-breadcrumb>
      <h1 class="">
        {{ idea.title }}
      </h1>
      <social-sharing
        :title="idea.title"
        :description="idea.description"
        quote="Nuvola - Nội thất gia đình."
        hashtags="nuvola-danang, NoiThatGiaDinhDaNang"
        inline-template
      >
        <div>
          <network network="facebook" class="nuvola-cursor-pointer">
            <i class="fab fa-facebook" style="color: #1E88E5"></i> Facebook
          </network>
          <network network="pinterest" class="nuvola-cursor-pointer">
            <i class="fab fa-pinterest" style="color: red"></i> Pinterest
          </network>
          <network network="twitter" class="nuvola-cursor-pointer">
            <i class="fab fa-twitter" style="color: #1E88E5"></i> Twitter
          </network>
        </div>
      </social-sharing>
      <div class="card-thumbnail idea-thumbnail" :style="{ 'background-image': 'url(' + idea.thumbnail + ')' }">
      </div>
      <div class="mb-4">
      </div>
      <div class="nuvola-paragraph idea--content" v-html="idea.content"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {store} from "@/store";

async function initData(to, next) {
  if (to.params.slug !== undefined) {
    await store.dispatch(
      "fetchIdea",
      to.params.slug
    );
  }
  if (store.getters.ideaFetchError) {
    next({name: "/404"})
  }
  return next();
}

export default {
  name: "IdeaDetail",
  async beforeRouteUpdate(to, from, next) {
    return await initData(to, next);
  },
  async beforeRouteEnter(to, from, next) {
    return await initData(to, next);
  },
  computed: {
    ...mapGetters(
      [
        "idea",
      ]
    )
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      this.$scrollTo("#top")
    },
  }
}
</script>

<style lang="scss">
  .idea-container {
    @media only screen and (min-width: 768px) {
      padding: 1.875rem 2.5rem !important;
    }

    @media only screen and (min-width: 1200px) {
      padding: 1.875rem 3rem !important;
    }
  }

  .idea-thumbnail {
    margin-top: 56px;
    padding-top: 40%;
  }

  .idea--content {
    font-size: 18px;
    p {
      margin-top: 2em;
    }
    .image {
      text-align: center;
      img {
        text-align: center;
      }
    }
    img {
      max-width: 100%;
      text-align: center;
    }
    table {
      width: 100%;
    }
  }
</style>