<template>
  <div>
    <div class="d-block">
      <div v-if="thumbnails.length" class="product-thumbnail-slider product-slider">
        <slick
          ref="slickFor"
          class="slider-for mb-4"
          :options="slickForOptions"
        >
          <div
            v-for="(thumbnail, index) in thumbnails" :key="index"
            class="d-flex justify-content-center"
          >
            <div class="product-thumbnail-slider--main" :style="{ 'background-image': 'url(' + thumbnail + ')' }">
            </div>
          </div>
        </slick>
        <slick
          ref="slickNav"
          class="slider-nav"
          :options="slickNavOptions"
        >
          <div
            v-for="(thumbnail, index) in thumbnails" :key="index"
            class="d-flex justify-content-center"
          >
            <div class="product-thumbnail-slider--nav nuvola-cursor-pointer" :style="{ 'background-image': 'url(' + thumbnail + ')' }">
            </div>
          </div>
        </slick>
      </div>
      <div v-else>
        <a-skeleton
          active style="height: 496px"
          :paragraph="{rows: 10}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
  name: "TestingProductThumbnailsSlider",
  components: {
    Slick
  },
  props: {
    thumbnails: {
      required: true,
      type: Array
    }
  },

  data() {
    return {
      slickForOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        accessibility: true,
        adaptiveHeight: false,
        arrows: true,
        edgeFriction: 0.30,
        swipe: true,
        asNavFor: '.slider-nav'
      },
      slickNavOptions: {
        infinite: true,
        accessibility: true,
        adaptiveHeight: false,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: '.slider-for',
        swipe: true,
        dots: true,
      },
    }
  },
  beforeUpdate() {
    if (this.$refs.slickFor && this.$refs.slickNav) {
      this.$refs.slickFor.destroy();
      this.$refs.slickNav.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slickFor && this.$refs.slickNav) {
        this.$refs.slickFor.create(this.slickForOptions);
        this.$refs.slickNav.create(this.slickNavOptions);
      }
    });
  },
}
</script>

<style lang="scss" scoped>
  .product-thumbnail-slider {
    width: 95%;
    margin: auto;
    .product-thumbnail-slider--main {
      width: 100%;
      padding-top: 100%;
      @media only screen and (min-width: 576px) {
        height: 400px;
        width: 400px;
        padding-top: 0;
      }
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .product-thumbnail-slider--nav {
      width: 100%;
      height: 80px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 0.5rem;
    }
  }

</style>