<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Danh sách sản phẩm
      </h1>
    </div>
    <div class="w-100 d-flex justify-content-end mb-4">
      <router-link
        to="create-product"
      >
        <a-button
          icon="plus" type="primary"
          class="d-flex align-items-center"
        >
          Thêm sản phẩm
        </a-button>
      </router-link>
    </div>
    <div>
      <div v-if="filteredInfo" class="mb-2">
        <template v-for="(value, key, index) in filteredInfo">
          <a-tag
            v-if="value && value.length !== 0" :key="index"
            :closable="true" @close="resetFilter(key)"
          >
            <span v-for="(item, itemIndex) in value" :key="itemIndex">{{ item }},  </span>
          </a-tag>
        </template>
      </div>
      <a-table
        :columns="columns" :data-source="products"
        :row-key="category => category.id"
        size="middle"
        :loading="loading"
        @change="handleChange"
      >
        <span slot="thumbnail" slot-scope="text, product">
          <span>{{ product.id }}</span>
        </span>
        <span
          slot="productItemName" slot-scope="text, product"
          class="d-flex align-items-center"
        >
          <a-tooltip>
            <template slot="title">
              Sản phẩm hàng đầu
            </template>
            <a-icon
              v-if="product.topProduct" type="star"
              theme="twoTone" two-tone-color="#52c41a"
              class="mr-2"
            ></a-icon>
          </a-tooltip>

          <span class="nuvola-cursor-pointer font-weight-bold" @click="navigateToEdit(product.id)">{{ product.name }}</span>
        </span>
        <span slot="category" slot-scope="text, product">
          {{ product.category ? product.category.name : "Không danh mục" }}
        </span>
        <span slot="price" slot-scope="text, product">
          {{ product.onRequest ? "Liên hệ" : formatCurrency(product.price) }}
        </span>
        <span slot="metadata" slot-scope="text, product">
          <span class="nuvola-font-size-12">{{ product.createdAt | moment("DD/MM/YYYY") }}</span>
        </span>
        <span slot="action" slot-scope="text, product">
          <a-dropdown placement="bottomLeft" :trigger="['click']">
            <a-button
              shape="circle" icon="ellipsis"
              class="d-flex align-items-center justify-content-center"
            />
            <a-menu slot="overlay">
              <a-menu-item @click="navigateToEdit(product.id)">
                Chỉnh sửa
              </a-menu-item>
              <a-menu-item v-if="!product.topProduct" @click="setTopProduct(product.id, true)">
                Cập nhật sản phẩm hàng đầu
              </a-menu-item>
              <a-menu-item v-if="product.topProduct" @click="setTopProduct(product.id, false)">
                Bỏ sản phẩm hàng đầu
              </a-menu-item>
              <a-menu-item @click="showConfirm(product.id)">
                Xóa
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {ProductService} from "../../../store/services/api-service";
import { formatCurrency } from "../../../utils/common-utils"
import {ActionTypes} from "../../../constants/action-types";

export default {
  name: "ProductOverview",
  data() {
    return {
      loading: false,
      filteredInfo: null,
      sortedInfo: null,
      query: ''
    }
  },
  computed: {
    ...mapGetters([
      "products",
      "loadCategories"
    ]),
    filterCategories() {
      return this.loadCategories.map(category => {
        return {
          text: category.name,
          value: category.name
        }
      })
    },
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: 'ID',
          dataIndex: 'thumbnail',
          key: 'thumbnail',
          scopedSlots: {customRender: 'thumbnail'},
          width: '10%'
        },
        {
          title: 'Tên sản phẩm',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'productItemName'},
          width: '30%',
          filters: [{text: 'Danh mục hàng đầu', value: "Danh mục hàng đầu"}],
          filteredValue: filteredInfo.name || null,
          onFilter: (value, record) => record.topProduct
        },
        {
          title: 'Loại sản phẩm',
          dataIndex: 'category',
          key: 'category',
          scopedSlots: {customRender: 'category'},
          width: '20%',
          filters: this.filterCategories,
          filteredValue: filteredInfo.category || null,
          onFilter: (value, record) => record.categoryName.includes(value)
        },
        {
          title: 'Giá',
          dataIndex: 'price',
          key: 'price',
          scopedSlots: {customRender: 'price'},
          width: '10%',
          filters: [{text: 'Liên hệ', value: "Giá liên hệ"}],
          filteredValue: filteredInfo.price || null,
          onFilter: (value, record) => record.onRequest
        },
        {
          title: 'Ngày tạo',
          dataIndex: 'metadata',
          key: 'metadata',
          scopedSlots: {customRender: 'metadata'},
          width: '20%'
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: '10%'
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchProducts");
    await this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
    this.loading = false;
  },
  methods: {
    navigateToEdit(id) {
      this.$router.push({
        name: "editProduct",
        params : {
          slug: id
        }
      });
    },
    showConfirm: function(id) {
      const that = this;
      this.$confirm({
        title: 'Bạn có chắc chắn xóa sản phẩm này?',
        okType: 'danger',
        onOk() {
          that.deleteProduct(id)
        }
      });
    },
    deleteProduct(id) {
      ProductService.delete(id).then(() => {
        this.$store.dispatch("fetchProducts");
        this.$message.success('Xóa sản phẩm thành công!');
      }).catch(error => {
        this.$message.error('Có lỗi xảy ra!' + error.message);
        console.log(error);
      });
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    setTopProduct(productId, topProduct) {
      this.loading = true;
      this.$store.dispatch("setTopProduct", {productId, topProduct}).then(() => {
        return this.$store.dispatch("fetchProducts");
      }).then(products => {
        this.$message.success('Cập nhật sản phẩm hàng đầu thành công!');
      }).catch(error => {
        this.$message.error('Có lỗi xảy ra!' + error.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    handleChange(pagination, filters, sorter) {
      console.log("pagination, filters, sorter=", pagination, filters, sorter)
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    resetFilter(key) {
      this.filteredInfo[key] = null;
    }
  },
}
</script>

<style>
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .input-search {
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 25px;
  }
</style>