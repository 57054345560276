<template>
  <div>
    <div id="top"></div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>

<style lang="scss">
@import "static/scss/base";
</style>
