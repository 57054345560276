<template>
  <router-link
    :to="`/category/${category.id}`"
  >
    <div
      :class="[level, 'background-center']"
      :style="{ 'background-image': 'url(' + category.thumbnail + ')' }"
    >
      <div class="gradient-cover w-100 h-100 position-absolute">
      </div>
      <div class="nuvola--p-24 feature-content">
        <h2 class="text-white">
          {{ category.name }}
        </h2>
      </div>
    </div>
  </router-link>
</template>

<script>
    export default {
        name: "FeatureCategory",
        props: {
            category: {
                type: Object,
                required: true
            },
            level: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
    .top-feature {
        height: 440px;
        width: 100%;
    }

    .second-feature {
        height: 245px;
        width: 100%;
    }

    .third-feature {
        height: 195px;
        width: 100%;
    }

    .background-center {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .gradient-cover {
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6979166666666667) 35%, rgba(0, 212, 255, 0) 100%);
    }
</style>