<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <router-link to="/admin/common-settings">
        <a-button
          type="primary" shape="circle"
          icon="left"
          size="small" class="d-flex justify-content-center mr-3"
        />
      </router-link>
      <h1 style="font-size: 1.5rem" class="mb-0">
        Tùy chỉnh trang liên hệ
      </h1>
    </div>
    <div class="mb-4">
      <span>Title</span>
      <a-input v-model="organizationSettings.contactTitle" placeholder="Tiêu đề trang liên hệ"></a-input>
    </div>

    <a-button size="small" @click="showModal">
      <a-icon type="picture" /> Thêm ảnh từ URL
    </a-button>
    <a-upload
      name="ideaImage"
      :show-upload-list="false"
      :before-upload="beforeUploadThumbnail"
      @change="handleChangeIdeaImage"
    >
      <a-button
        size="small" class="ml-1"
      >
        <a-icon type="cloud-upload" />
        Thêm ảnh từ máy tính
      </a-button>
    </a-upload>
    <tinymce
      id="id1" ref="tm"
      v-model="organizationSettings.contactContent" :other_options="options"
      @editorInit="onInitEditor"
    ></tinymce>
    <a-button
      class="mt-3"
      type="primary"
      @click="handleSubmit"
    >
      Lưu thiết lập
    </a-button>
    <a-modal
      v-model="visible"
      @ok="handleOk"
    >
      <span>Đường dẫn tới ảnh</span>
      <a-input v-model="imageUrl" placeholder="Link URL"></a-input>
    </a-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {store} from "@/store";
import {isBlank} from "../../../utils/common-utils";
import {UploadService} from "../../../store/services/api-service";
import {BucketType} from "../../../constants/const";
const VUE_APP_API_URL = process.env.VUE_APP_API_URL || "";
const env = process.env.NODE_ENV || "";

export default {
  name: "ContactSettings",
  data() {
    return {
      visible: false,
      imageUrl: '',
      options: {
        height: "50vh",
        min_height: 1000,
        toolbar2: "undo redo | image ",
        content_css : 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
        content_css_cors: true,
        content_style: "img {max-width: 100%;} body {font-family: 'Roboto', sans-serif;}",
        plugins: [
          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'template paste textcolor colorpicker textpattern toc help emoticons hr codesample'
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      "organizationSettings"
    ])
  },
  mounted() {
    this.organizationSettings.contactContent = this.organizationSettings.contactContent + " ";
  },
  methods: {
    beforeUploadThumbnail() {
      return false;
    },
    insertContent(content) {
      this.$refs.tm.editor.execCommand('mceInsertContent', false, content);
    },
    onInitEditor() {
      this.organizationSettings.contactContent = this.organizationSettings.contactContent + " ";
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      if (!isBlank(this.imageUrl)) {
        let contentToInsert = `<img src="${this.imageUrl}" alt=""  />`;
        this.insertContent(contentToInsert);
      }
      this.imageUrl = '';
      this.visible = false;
    },
    handleSubmit() {
      this.$store.dispatch("saveOrganizationSettings").then(() => {
        this.$message.success("Lưu thiết lập thành công!")
      }).catch(error => {
        this.$message.error("Có lỗi xảy ra!")
      });
    },
    handleChangeIdeaImage(info) {
      const file = this.validateImage(info);
      if (file) {
        UploadService.uploadSingle(BucketType.IDEA_CONTENT, file).then(res => {
          let prePath = env === "development" ? VUE_APP_API_URL : "";
          let newImage = `<img src="${prePath}${res.data.path}" alt=""  />`;
          this.insertContent(newImage);
        }).catch(error => {
          console.log(error);
          this.$message.error('Có lỗi xảy ra!');
        })
      }
    },
    validateImage: function (info) {
      const file = info.file;
      const isValidImageType = (file.type === 'image/jpeg') || (file.type === 'image/png');
      if (!isValidImageType) {
        this.$message.error('You can only upload JPG or PNG file!');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('Image must smaller than 5MB!');
      }
      if (isValidImageType && isLt5M) {
        return file
      } else {
        return null;
      }
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('fetchOrganizationSettings');
    return next();
  },
}
</script>

<style scoped>
  #id1_ifr {
    min-height: 600px;
  }
</style>