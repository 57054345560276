module.exports = {
  productItems: [
    {
      id: 1,
      name: "Giường ngủ",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0625270_PE692176_S5.JPG?f=xxs"
    },
    {
      id: 2,
      name: "Giường ngủ 2",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0638582_PE699001_S5.JPG?f=xxs"
    },
    {
      id: 3,
      name: "Giường ngủ 3",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0749129_PE745498_S5.JPG?f=xxs"
    },
    {
      id: 4,
      name: "Giường ngủ 4",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0637607_PE698419_S5.JPG?f=xxs"
    },
    {
      id: 5,
      name: "Giường đôi",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0638044_PE698660_S5.JPG?f=xxs"
    },
    {
      id: 6,
      name: "Giường ngủ 6",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0179752_PE331952_S5.JPG?f=xxs"
    },
    {
      id: 7,
      name: "Giường đôi",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0687345_PE722121_S5.JPG?f=xxs"
    },
    {
      id: 8,
      name: "Giường ngủ 8",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0555081_PE660118_S5.JPG?f=xxs"
    },
    {
      id: 9,
      name: "Giường ngủ 9",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0637614_PE698424_S5.JPG?f=xxs"
    },
    {
      id: 10,
      name: "Giường ngủ",
      description: "Giường đẹp, thoải mái và êm ái",
      price: "2999000",
      isNew: true,
      thumbnailUrl:
        "https://www.ikea.com/PIAimages/0625270_PE692176_S5.JPG?f=xxs"
    }
  ]
};