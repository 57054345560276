<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <router-link to="/admin/products">
        <a-button
          type="primary" shape="circle"
          icon="left"
          size="small" class="d-flex justify-content-center mr-3"
        />
      </router-link>
      <h1 style="font-size: 1.5rem" class="mb-0">
        {{ pageHeader }}
      </h1>
    </div>
    <div>
      <a-form
        :form="productForm"
        @submit="handleSubmit"
      >
        <a-form-item
          v-bind="formItemLayout" label="Tên sản phẩm"
          class="mb-0"
        >
          <a-input
            ref="productName"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng nhập tên sản phẩm!',
                  },
                ],
              },
            ]"
            placeholder="Tên sản phẩm!"
          />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout" label="Mô tả ngắn"
          class="mb-0"
        >
          <a-textarea
            v-decorator="[
              'description',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng nhập mô tả!',
                  },
                ],
              },
            ]"
            rows="2"
            placeholder="Mô tả ngắn về sản phẩm! (Chất liệu, bộ sưu tập...)"
          />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout" label="Danh mục"
          class="mb-0"
        >
          <a-select
            v-decorator="[
              'categoryId',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng chọn danh mục!'
                  }
                ]
              },
            ]"
            style="width: 50%"
            placeholder="Vui lòng chọn danh mục!"
          >
            <a-select-option v-for="category in loadCategories" :key="category.id">
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout" label="Giá liên hệ"
          class="mb-0"
        >
          <a-switch v-decorator="['onRequest', { valuePropName: 'checked' }]" />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout" label="Giá"
          class="mb-0"
          :class="productForm.getFieldValue('onRequest') ? 'd-none' : ''"
        >
          <a-input-number
            ref="productPrice"
            v-decorator="[
              'price',
              {
                rules: [ { validator: checkPriceValid } ],
              },
            ]"
            class="w-100"
            :min="0"
            :step="0.1"
            placeholder="Giá sản phẩm!"
            :formatter="value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\₫\s?|(,*)/g, '')"
          >
            <a-icon slot="suffix" type="user" />
          </a-input-number>
        </a-form-item>
        <a-input ref="thumbnailLabel" class="d-none"></a-input>
        <div class="offset-2 ant-form-item-label">
          <label for="span-hidden-2" class="ant-form-item-required">Ảnh mô tả</label>
          <span id="span-hidden-2"></span>
        </div>
        <div class="clearfix offset-2">
          <a-upload
            list-type="picture-card"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 5">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible" :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example" style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
        </div>
        <div class="offset-2 my-4">
          <h5>Chi tiết sản phẩm</h5>
        </div>
        <a-form-item
          v-for="specification in currentSpecifications"
          :key="specification.id"
          v-bind="formItemLayout" :label="specification.label"
          class="mb-0"
        >
          <a-textarea
            v-decorator="[
              `specificationContents[${specification.id}]`
            ]"
            rows="4"
            :placeholder="specification.label"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
          <a-button
            type="primary" html-type="submit"
            :loading="saving"
          >
            Lưu sản phẩm
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "../../../constants/action-types";
import {isBlank, replaceAll} from "../../../utils/common-utils";
import {store} from "@/store";
import {UploadService} from "../../../store/services/api-service";
import {BucketType} from "../../../constants/const"
import _ from "lodash";

let isEdit = false;

export default {
  name: "CreateProduct",
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch("resetProductState");
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("resetProductState");
    if (to.params.slug !== undefined) {
      isEdit = true;
      await store.dispatch(
        "fetchProduct",
        to.params.slug
      );
    } else {
      isEdit = false;
    }
    return next();
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 4},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      },
      previewVisible: false,
      previewImage: '',
      fileList: [],
      saving: false,
      currentSpecifications: [],
      specificationContents: [],
      deletedFiles: []
    }
  },
  computed: {
    ...mapGetters(
      [
        "product",
        "loadCategories",
        "specifications"
      ]
    ),
    saveAction: function () {
      return isEdit ? "updateProduct" : "createProduct";
    },
    pageHeader: function () {
      return isEdit ? "Cập nhật sản phẩm" : "Tạo sản phẩm"
    },
    saveSuccessMessage() {
      return isEdit ? "Sản phẩm được cập nhật thành công!" : "Sản phẩm được tạo thành công!";
    }
  },
  created() {
    this.productForm = this.$form.createForm(this, {
      name: "productForm"
    });
  },
  async mounted() {
    await this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
    if (!isEdit) {
      // set specifications
      await this.$store.dispatch("fetchDefaultSpecification");
      this.currentSpecifications = this.specifications;
      await _.forEach(this.currentSpecifications, specification => {
        this.specificationContents[specification.id] = ""
      });
    } else {
      // set specifications
      await this.$store.dispatch("setSpecifications", this.product.specifications);
      this.currentSpecifications = this.specifications;
      await _.forEach(this.currentSpecifications, specification => {
        this.specificationContents[specification.id] = specification.productItemSpecifications.content
      });
      // set thumbnails
      this.fileList = this.product.productItemThumbnails.map(thumbnail => {
        return {
          uid: `${_.uniqueId()}${Date.now()}`,
          url: thumbnail.uri,
          name: "product item thumbnail",
          productItemThumbnailId: thumbnail.id
        }
      });
    }
    this.productForm.setFieldsValue({
      specificationContents: this.specificationContents
    });

    this.productForm.setFieldsValue({
      name: this.product.name,
      description: this.product.description,
      categoryId: this.product.categoryId,
      price: this.product.price,
      onRequest: this.product.onRequest
    });
    console.log("this.product.onReques1t=", this.product.onRequest)
  },
  methods: {
    saveProduct: function (productPayload) {
      this.$store.dispatch("updateProductState", productPayload).then(() => {
        return this.$store.dispatch(this.saveAction)
      }).then(() => {
        this.$router.push("/admin/products");
        this.$message.success(this.saveSuccessMessage);
      }).catch(error => {
        this.$message.error('Có lỗi xảy ra! ' + error.message);
      }).finally(() => {
        this.saving = false;
      });
    }, handleSubmit(e) {
      e.preventDefault();
      this.productForm.validateFields((err, values) => {
        if (err) {
          return;
        }
        if (!this.fileList || this.fileList.length === 0) {
          this.$message.error('Vui lòng đăng ảnh mô tả!');
          return;
        }
        this.saving = true;
        let productPayload = {
          ...this.product,
          ...values,
          deletedFiles: this.deletedFiles
        };
        if (this.fileList && this.fileList.length > 0) {
          // Create product with thumbnails
          let thumbnailFiles = this.fileList.map(file => file.originFileObj);
          UploadService.uploadMultiple(BucketType.PRODUCT, thumbnailFiles).then(res => {
            let productItemThumbnails = res.data.map(thumbnail => thumbnail.path);
            this.saveProduct({
              ...productPayload,
              thumbnails: productItemThumbnails
            });
          }).catch(error => {
            this.$message.error('Có lỗi xảy ra! ' + error.message);
          }).finally(() => {
            this.saving = false;
          })
        } else {
          // create products without thumbnails
          this.saveProduct(productPayload)
        }
      })
    },
    checkPriceValid: function (rule, value, callback) {
      let onRequest = this.productForm.getFieldValue("onRequest");
      if (onRequest) {
        callback();
      } else if (value === null || value === undefined) {
        callback("Vui lòng nhập giá sản phẩm!");
      } else {
        callback();
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handleChange(event) {
      let fileList = event.fileList;
      let changedFile = event.file;
      // Handle remove thumbnail
      if (changedFile.status && changedFile.status === "removed") {
        if (changedFile.url) {
          this.deletedFiles.push(changedFile);
        }
        this.fileList = fileList;
        return;
      }
      // Handle add thumbnail
      if (fileList.length !== 0) {
        let justUploadedFile = fileList[fileList.length - 1];
        const isValidImageType = (justUploadedFile.type === 'image/jpeg') || (justUploadedFile.type === 'image/png');
        if (!isValidImageType) {
          this.$message.error('Chỉ hỗ trợ ảnh JPG và PNG!');
          return;
        }
        const isLt5M = justUploadedFile.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('Dung lượng ảnh phải nhỏ hơn 5 MB!');
          return;
        }
      }
      this.fileList = fileList;
      this.$refs.productPrice.focus();
      _.delay(() => {
        this.$refs.productPrice.blur();
      }, 500)
    },
    beforeUpload() {
      return false;
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch("resetProductState");
    next();
  }
}
</script>

<style scoped>

</style>