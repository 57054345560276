import Vue from "vue";
import Vuex from "vuex";
import {category} from "./modules/category";
import {product} from "./modules/product";
import {opportunity} from "./modules/opportunity";
import {customer} from "./modules/customer";
import specification from "./modules/specification";
import auth from "./modules/auth";
import room from "./modules/room";
import organizationSettings from "./modules/organization-settings"
import idea from "./modules/idea"
import utility from "./modules/utility"
Vue.use(Vuex);

export const store = new Vuex.Store({
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    category,
    product,
    room,
    specification,
    opportunity,
    organizationSettings,
    idea,
    utility,
    customer,
  }
});
