var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"30px"}},[_c('router-link',{attrs:{"to":"/admin/rooms"}},[_c('a-button',{staticClass:"d-flex justify-content-center mr-3",attrs:{"type":"primary","shape":"circle","icon":"left","size":"small"}})],1),_c('h1',{staticClass:"mb-0",staticStyle:{"font-size":"1.5rem"}},[_vm._v("\n      "+_vm._s(_vm.pageHeader)+"\n    ")])],1),_c('a-form',{attrs:{"form":_vm.roomForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"Tên loại phòng"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                validator: _vm.checkNameValid
              },
              {
                required: true,
                message: 'Vui lòng nhập tên loại phòng!',
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                validator: checkNameValid\n              },\n              {\n                required: true,\n                message: 'Vui lòng nhập tên loại phòng!',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Tên loại phòng!"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"Mô tả"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            rules: [
              {
                required: true,
                message: 'Vui lòng nhập mô tả!',
              } ],
          } ]),expression:"[\n          'description',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Vui lòng nhập mô tả!',\n              },\n            ],\n          },\n        ]"}],attrs:{"rows":"4","placeholder":"Mô tả loại phòng!"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"Các loại sản phẩm"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['categoryIds']),expression:"['categoryIds']"}],staticClass:"w-100",staticStyle:{"width":"200px"},attrs:{"mode":"multiple","placeholder":"Vui lòng chọn các loại sản phẩm thuộc loại phòng này!"}},_vm._l((_vm.loadCategories),function(category){return _c('a-select-option',{key:category.id},[_vm._v("\n          "+_vm._s(category.name)+"\n        ")])}),1)],1),_c('a-form-item',{staticClass:"mb-2",attrs:{"label":"Ảnh mô tả","label-col":{ span: 5 },"wrapper-col":{ span: 18 },"required":"true"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUploadThumbnail},on:{"change":_vm.handleChangeThumbnail}},[(_vm.file)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.imageUrl,"alt":"thumbnail"}}):_vm._e(),(!_vm.file && _vm.room.thumbnail)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.room.thumbnail,"alt":"thumbnail"}}):_vm._e(),(!_vm.file && !_vm.room.thumbnail)?_c('div',[_c('a-icon',{attrs:{"type":_vm.uploadLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n            Upload\n          ")])],1):_vm._e()])],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saving}},[_vm._v("\n        Lưu loại phòng\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }