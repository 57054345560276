<template>
  <div class="header-image-slider d-flex justify-content-center bg-white w-100 row m-0">
    <div class="left-slider">
      <a-carousel
        :arrows="true" :autoplay="false"
      >
        <div
          slot="prevArrow"
          slot-scope=""
          class="custom-slick-arrow"
          style="left: 10px; z-index: 1"
        >
          <a-icon type="left-circle"></a-icon>
        </div>
        <div
          slot="nextArrow" slot-scope=""
          class="custom-slick-arrow" style="right: 10px"
        >
          <a-icon type="right-circle"></a-icon>
        </div>
        <div v-for="(item, index) in items.leftSlider" :key="index">
          <div class="home-image-slider-left" :style="{ 'background-image': 'url(' + item.src + ')' }"></div>
        </div>
      </a-carousel>
    </div>
    <div class="right-slider">
      <a-carousel
        :arrows="true" :autoplay="true"
      >
        <div
          slot="prevArrow"
          slot-scope=""
          class="custom-slick-arrow"
          style="left: 10px; z-index: 1"
        >
          <a-icon type="left-circle"></a-icon>
        </div>
        <div
          slot="nextArrow" slot-scope=""
          class="custom-slick-arrow" style="right: 10px"
        >
          <a-icon type="right-circle"></a-icon>
        </div>
        <div v-for="(item, index) in items.rightSlider" :key="index">
          <div class="home-image-slider-right" :style="{ 'background-image': 'url(' + item.src + ')' }"></div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesSlider",
  props: {
    items: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .left-slider {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .right-slider {
    width: 100%;
    max-width: 100%;
  }

  @media only screen and (min-width: 576px) {
    .left-slider {
      width: 66.5%;
      max-width: 1000px;
      margin-right: 0.2%;
    }
    .right-slider {
      width: 33.3%;
      max-width: 500px;
    }
  }

  .home-image-slider-right {
    padding-top: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home-image-slider-left {
    width: 100%;
    padding-top: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .header-image-slider {
    .ant-carousel >>> .slick-slide {
      text-align: center;
      height: 500px;
      line-height: 160px;
      background: #364d79;
      overflow: hidden;
    }

    .ant-carousel >>> .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #fff;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }

    .ant-carousel >>> .custom-slick-arrow:before {
      display: none;
    }

    .ant-carousel >>> .custom-slick-arrow:hover {
      color: white;
      opacity: 0.7;
    }

    .slick-dots li button:before {
      display: none !important;
      color: white !important;
      opacity: 0 !important;
    }
  }
</style>