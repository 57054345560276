<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Tùy chỉnh
      </h1>
    </div>
    <div class="row m-0">
      <div @click="navigateTo('contact-settings')">
        <app-setting-item
          label="Trang liên hệ"
          icon="share-alt"
        ></app-setting-item>
      </div>
      <div @click="navigateTo('home-slider-manager')">
        <app-setting-item
          label="Slider trang chủ"
          icon="fast-forward"
        ></app-setting-item>
      </div>
    </div>
  </div>
</template>

<script>
import SettingItem from "./SettingItem"

export default {
  name: "CommonSettings",
  components: {
    appSettingItem: SettingItem
  },
  methods: {
    navigateTo(path) {
      console.log(path)
      this.$router.push(`/admin/${path}`).catch(error => {
      });
    }
  }
}
</script>

<style scoped>

</style>