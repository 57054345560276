import { render, staticRenderFns } from "./SearchProducts.vue?vue&type=template&id=531f85cd&scoped=true&"
import script from "./SearchProducts.vue?vue&type=script&lang=js&"
export * from "./SearchProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531f85cd",
  null
  
)

export default component.exports