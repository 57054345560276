<template>
  <div>
    <div class="shadow-sm nuvola-border-radius-8 p-3 border">
      <div class="d-flex align-items-center">
        <a-icon type="home" class="mr-2" /> <span>{{ address.streetNumber }} {{ address.streetName }}, {{ address.ward }}, {{ address.district }}</span>
      </div>
      <div class="d-flex align-items-center">
        <a-icon type="environment" class="mr-2" /> <span>{{ address.city }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerAddressComponent",
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>