<template>
  <div class="product--specification">
    <a-collapse v-model="activeKey" :bordered="false">
      <a-collapse-panel
        key="1" :header="specification.label"
      >
        <p style="white-space: pre-line;">
          {{ specification.content }}
        </p>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
export default {
  name: "ProductSpecification",
  props: {
    specification: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      activeKey: ['1']
    };
  }
}
</script>

<style lang="scss">
  .product--specification {
    .ant-collapse .ant-collapse-item .ant-collapse-header {
      font-weight: bold;
      font-size: 18px;
    }
  }
</style>