<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Danh sách tiềm năng
      </h1>
    </div>
    <!-- ADD BUTTON -->
    <div class="w-100 d-flex justify-content-end mb-4">
      <router-link
        to="create-product"
      >
        <a-button
          icon="plus" type="primary"
          class="d-flex align-items-center"
        >
          Thêm cơ hội
        </a-button>
      </router-link>
    </div>
    <!-- DATATABLE -->
    <div>
      <a-table
        :columns="columns" :data-source="opportunities"
        :row-key="opportunity => opportunity.id"
        size="middle"
        :loading="loading"
        :custom-row="opportunityCustomerRow"
        :row-class-name="opportunityRowClassname"
        @change="handleChange"
      >
        <span
          slot="code" slot-scope="text, opportunity"
          class="d-flex align-items-center"
        >
          <span class="font-weight-bold">#{{ opportunity.id }}</span>
        </span>
        <span
          slot="opportunityName" slot-scope="text, opportunity"
          class="d-flex align-items-center"
        >
          {{ opportunity.name }}
        </span>
        <span slot="customer" slot-scope="text, opportunity">
          <a-tooltip>
            <template slot="title">
              <div>
                <span class="font-weight-bold">{{ opportunity.customer.name }}</span>
              </div>
              <div>
                <a-icon type="phone" class="mr-2" />
                <span>{{ opportunity.customer.phoneNumber }}</span>
              </div>
              <div v-if="opportunity.customer.email">
                <a-icon type="mail" class="mr-2" />
                <span>{{ opportunity.customer.email }}</span>
              </div>
            </template>
            <span
              class="nuvola-cursor-pointer text-link-primary"
              @click="navigateTo('customerDetail', opportunity.customer.id)"
            >
              {{ opportunity.customer.name }}
            </span>
          </a-tooltip>
        </span>
        <span slot="product" slot-scope="text, opportunity">
          {{ opportunity.productItem.name }}
        </span>
        <span slot="createdDate" slot-scope="text, opportunity">
          {{ opportunity.createdAt | moment("DD/MM/YYYY") }}
        </span>
        <div slot="status" slot-scope="text, opportunity">
          <a-tag v-if="opportunity.cancel" color="#FF5754">
            {{ 'Hủy' }}
          </a-tag>
          <a-tag v-else :color="opportunity.statusObject.color">
            {{ opportunity.statusObject.label }}
          </a-tag>
        </div>
        <span slot="action" slot-scope="text, opportunity">
          <a-dropdown placement="bottomLeft" :trigger="['click']">
            <a-button
              shape="circle" icon="ellipsis"
              class="d-flex align-items-center justify-content-center"
            />
            <a-menu slot="overlay">
              <a-menu-item @click="navigateToEdit(opportunity.id)">
                Chỉnh sửa
              </a-menu-item>
              <a-menu-item @click="showConfirm(opportunity)">
                Xóa
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {OpportunityService} from "../../../store/services/api-service";
import _ from "lodash"

export default {
  name: "OpportunityOverview",
  data() {
    return {
      loading: false,
      filteredInfo: null,
      sortedInfo: null,
    }
  },
  computed: {
    ...mapGetters([
      "opportunities"
    ]),
    filterOpportunities() {
      return this.opportunities.map(opportunity => {
        return {
          text: opportunity.name,
          value: opportunity.name
        }
      })
    },
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: 'Mã',
          dataIndex: 'code',
          key: 'code',
          scopedSlots: {customRender: 'code'},
          width: '5%'
        },
        {
          title: 'Tên',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'opportunityName'},
          width: '20%',
          filters: [],
          filteredValue: filteredInfo.name || null,
          onFilter: (value, record) => record.name.includes(value)
        },
        {
          title: 'Khách hàng',
          dataIndex: 'customer',
          key: 'customer',
          scopedSlots: {customRender: 'customer'},
          width: '10%',
          filters: [],
          filteredValue: filteredInfo.customer || null,
          onFilter: (value, record) => record.categoryName.includes(value)
        },
        {
          title: 'Sản phẩm',
          dataIndex: 'product',
          key: 'product',
          scopedSlots: {customRender: 'product'},
          width: '20%',
          filters: [],
          filteredValue: filteredInfo.price || null,
          onFilter: (value, record) => record.categoryName.includes(value)
        },
        {
          title: 'Ngày tạo',
          dataIndex: 'createdDate',
          key: 'createdDate',
          scopedSlots: {customRender: 'createdDate'},
          width: '10%'
        },
        {
          title: 'Trạng thái',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: {customRender: 'status'},
          width: '10%',
          filters: [],
          filteredValue: filteredInfo.price || null,
          onFilter: (value, record) => record.categoryName.includes(value)
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: '5%'
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchOpportunities");
    this.loading = false;
  },
  methods: {
    opportunityCustomerRow(record, index) {
      return {
        on: {
          click: () => {
            this.navigateToEdit(record.id);
          }
        }
      };
    },
    opportunityRowClassname() {
      return "nuvola-cursor-pointer"
    },
    handleChange(pagination, filters, sorter) {
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    resetFilter(key) {
      this.filteredInfo[key] = null;
    },
    async deleteOpportunity(opportunity) {
      this.loading = true;
      OpportunityService.deleteById(opportunity.id).then(() => {
        this.$store.dispatch("fetchOpportunities");
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    showConfirm: function(opportunity) {
      const that = this;
      this.$confirm({
        title: 'Bạn có chắc chắn xóa cơ hội này?',
        okType: 'danger',
        onOk() {
          that.deleteOpportunity(opportunity)
        }
      });
    },
    navigateToEdit(id) {
      this.$router.push({
        name: "opportunityDetail",
        params: {
          slug: id
        }
      });
    },
    navigateTo(componentName, slug) {
      this.$router.push({
        name: componentName,
        params: {
          slug
        }
      }).catch(error => {});
    }
  },
}
</script>

<style>

</style>