var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"idea-detail-wrapper"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"30px"}},[_c('div',{staticClass:"d-flex align-items-center mr-auto"},[_c('router-link',{attrs:{"to":"/admin/ideas"}},[_c('a-button',{staticClass:"d-flex justify-content-center mr-3",attrs:{"type":"primary","shape":"circle","icon":"left","size":"small"}})],1),_c('h1',{staticClass:"mb-0",staticStyle:{"font-size":"1.5rem"}},[_vm._v("\n        Chi tiết bài viết\n      ")])],1)]),_c('div',[_c('a-form',{attrs:{"form":_vm.ideaForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Tiêu đề bài viết"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'title', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập tiêu đề!'
            }],
          }]),expression:"[ 'title', {\n            rules: [{\n              required: true,\n              message: 'Vui lòng nhập tiêu đề!'\n            }],\n          }]"}],staticClass:"mw-1000px",attrs:{"placeholder":"Tiêu đề bài viết!"}})],1),_c('a-form-item',{attrs:{"label":"Mô tả ngắn","extra":"Mô tả sẽ xuất hiện trên thẻ bài viết."}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'description' ]),expression:"[ 'description' ]"}],staticClass:"mw-1000px",attrs:{"placeholder":"Mô tả ngắn","rows":2}})],1),_c('div',{staticClass:"ant-form-item-label"},[_c('label',{staticClass:"ant-form-item-required",attrs:{"for":"span-hidden-2"}},[_vm._v("Ảnh mô tả")]),_c('span',{attrs:{"id":"span-hidden-2"}})]),_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUploadThumbnail},on:{"change":_vm.handleChangeThumbnail}},[(_vm.ideaThumbnail)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.imageUrl,"alt":"thumbnail"}}):_vm._e(),(!_vm.ideaThumbnail && _vm.idea.thumbnail)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.idea.thumbnail,"alt":"thumbnail"}}):_vm._e(),(!_vm.ideaThumbnail && !_vm.idea.thumbnail)?_c('div',[_c('a-icon',{attrs:{"type":_vm.uploadThumbnailLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n            Upload\n          ")])],1):_vm._e()]),_c('a-form-item',{attrs:{"label":"Các loại sản phẩm"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['categoryIds', {
            rules: [{
              required: true,
              message: 'Vui lòng chọn loại sản phẩm!'
            }],
          }]),expression:"['categoryIds', {\n            rules: [{\n              required: true,\n              message: 'Vui lòng chọn loại sản phẩm!'\n            }],\n          }]"}],staticClass:"w-100 mw-1000px",staticStyle:{"width":"200px"},attrs:{"mode":"multiple","placeholder":"Vui lòng chọn các loại sản phẩm !"}},_vm._l((_vm.loadCategories),function(category){return _c('a-select-option',{key:category.id},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])}),1)],1),_c('div',{staticClass:"ant-form-item-label"},[_c('label',{attrs:{"for":"span-hidden1"}},[_vm._v("Nội dung bài viết")]),_c('span',{attrs:{"id":"span-hidden1"}})]),_c('div',[_c('a-button',{attrs:{"size":"small"},on:{"click":_vm.showModal}},[_c('a-icon',{attrs:{"type":"picture"}}),_vm._v("\n          Thêm ảnh từ URL\n        ")],1),_c('a-upload',{attrs:{"name":"ideaImage","show-upload-list":false,"before-upload":_vm.beforeUploadThumbnail},on:{"change":_vm.handleChangeIdeaImage}},[_c('a-button',{staticClass:"ml-1",attrs:{"size":"small"}},[_c('a-icon',{attrs:{"type":"cloud-upload"}}),_vm._v("\n            Thêm ảnh từ máy tính\n          ")],1)],1)],1),_c('tinymce',{ref:"tm",attrs:{"id":"id1","other_options":_vm.options},model:{value:(_vm.idea.content),callback:function ($$v) {_vm.$set(_vm.idea, "content", $$v)},expression:"idea.content"}}),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","loading":_vm.saving},on:{"click":_vm.handleSubmit}},[_vm._v("\n          Lưu bài viết\n        ")])],1)],1)],1),_c('a-modal',{on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('span',[_vm._v("Đường dẫn tới ảnh")]),_c('a-input',{attrs:{"placeholder":"Link URL"},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }