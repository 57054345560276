import {SpecificationService} from "../services/api-service";

const state = {
  specifications: []
};

const mutations = {
  setSpecifications(state, specifications) {
    state.specifications = specifications;
  }
};

const actions = {
  resetSpecificationsState(context) {
    context.commit("setSpecifications", []);
  },
  setSpecifications(context, specifications) {
    context.commit("setSpecifications", specifications);
  },
  async fetchDefaultSpecification(context) {
    await SpecificationService.findDefaultSpecifications().then(res => {
      context.commit("setSpecifications", res.data);
    }).catch(error => {
      this.resetSpecificationsState(context);
      console.log(error);
    })
  }
};

const getters = {
  specifications: state => {
    return state.specifications;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};