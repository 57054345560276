<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container">
      <h1 class="text-center">
        Tìm kiếm sản phẩm
      </h1>
      <h2 v-if="searchTerm && searchTerm !== ''" class="text-center">
        "{{ searchTerm }}"
      </h2>
      <app-product-list :products="products"></app-product-list>
    </div>
  </div>
</template>

<script>
import ProductList from "../../components/main-page/ProductList"
import {isBlank} from "../../utils/common-utils"
import {mapGetters} from "vuex";
import {store} from "@/store";

let searchTerm = "";

async function init(to, next) {
  if (!isBlank(to.query.name)) {
    searchTerm = to.query.name;
    await store.dispatch("fetchSearchProducts", to.query.name);
  } else {
    await store.dispatch("updateProducts", []);
  }
  return next();
}

export default {
  name: "SearchProducts",
  components: {
    appProductList: ProductList
  },
  data() {
    return {
      searchTerm,
    }
  },
  computed: {
    ...mapGetters(
      [
        "products"
      ]
    ),
  },
  watch: {
    $route(to, from) {
      this.searchTerm = to.query.name
    }
  },
  async beforeRouteUpdate(to, from, next) {
    return await init(to, next);
  },
  async beforeRouteEnter(to, from, next) {
    return await init(to, next);
  }
}
</script>

<style scoped>

</style>