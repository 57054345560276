import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {store} from "./store";
import vuetify from "./plugins/vuetify";
import ApiService from "./store/services/api-service";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from 'vuelidate';
import VueLodash from 'vue-lodash';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueScrollTo from 'vue-scrollto';
import VueMoment from 'vue-moment'
import infiniteScroll from 'vue-infinite-scroll';
import SocialSharing from 'vue-social-sharing';
import tinymce from 'vue-tinymce-editor'

Vue.component('tinymce', tinymce);
Vue.config.productionTip = false;
ApiService.init();
Vue.use(BootstrapVue);
Vue.use(Antd);
Vue.use(Vuelidate);
Vue.use(VueLodash);
Vue.use(Loading, {
  color: 'red',
  opacity: 0.5,
  zIndex: 999,
});
Vue.use(VueScrollTo);
Vue.use(VueMoment);
Vue.use(infiniteScroll);
Vue.use(SocialSharing);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
