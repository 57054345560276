import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
import {
  CategoriesPath,
  Path,
  UploadPath,
  SpecificationsPath,
  RoomsPath,
  ProductPath,
  OpportunityPath,
  CustomerPath,
  IdeaPath
} from "../../constants/path";

const API_URL = process.env.VUE_APP_API_URL;
const headerConfig = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  formDataHeaders: {
    "Content-Type": "multipart/form-data",
  }
};
let axiosFormDataInstance;

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common = headerConfig.headers;
    axiosFormDataInstance = Vue.axios.create({
      baseURL: API_URL,
      headers: {
        common: headerConfig.formDataHeaders,
      }
    });
  },
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `jwt ${JwtService.getToken()}`;
    axiosFormDataInstance.defaults.headers.common["Authorization"] = `jwt ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}`).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  }
};
export default ApiService;

export const CategoriesService = {
  get() {
    return ApiService.get(Path.CATEGORIES);
  },
  create(params) {
    return ApiService.post(Path.CATEGORIES, params);
  },
  checkNameExisted(data) {
    return ApiService.post(CategoriesPath.CHECK_NAME_EXISTED, data);
  },
  update(category, id) {
    return ApiService.update(Path.CATEGORIES, id, category)
  },
  getMenu() {
    return ApiService.get(Path.CATEGORIES, "menu");
  },
  findById(id) {
    return ApiService.get(Path.CATEGORIES, id)
  },
  deleteById(id) {
    return ApiService.delete(Path.CATEGORIES, id);
  },
  getTopCategory() {
    return ApiService.get(Path.CATEGORIES, "top-category");
  }
};

export const RoomsService = {
  get() {
    return ApiService.get(Path.ROOMS);
  },
  create(room) {
    return ApiService.post(Path.ROOMS, room);
  },
  findAll() {
    return ApiService.get(Path.ROOMS);
  },
  findById(id) {
    return ApiService.get(Path.ROOMS, id)
  },
  deleteById(id) {
    return ApiService.delete(Path.ROOMS, id);
  },
  update(room, id) {
    return ApiService.update(Path.ROOMS, id, room)
  },
  checkNameValid(params) {
    return ApiService.post(RoomsPath.CHECK_NAME_VALID, params)
  }
};

export const UploadService = {
  uploadSingle(bucket, data) {
    let bodyFormData = new FormData();
    bodyFormData.set('image', data);
    return axiosFormDataInstance.post(`${UploadPath.UPLOAD_SINGLE}/${bucket}`, bodyFormData);
  },
  uploadMultiple(bucket, data) {
    let bodyFormData = new FormData();
    _.forEach(data, file => {
      bodyFormData.append("images", file);
    });
    return axiosFormDataInstance.post(`${UploadPath.UPLOAD_MULTIPLE}/${bucket}`, bodyFormData);
  }
};

export const SpecificationService = {
  findDefaultSpecifications() {
    return ApiService.get(SpecificationsPath.DEFAULT_SPECIFICATIONS);
  }
};

export const ProductService = {
  findAll() {
    return ApiService.get(Path.PRODUCT);
  },
  create(product) {
    return ApiService.post(Path.PRODUCT, product);
  },
  update(product) {
    return ApiService.put(Path.PRODUCT, product);
  },
  delete(id) {
    return ApiService.delete(Path.PRODUCT, id);
  },
  findById(id) {
    return ApiService.get(ProductPath.FIND_BY_ID, id);
  },
  findByCategoryId(categoryId) {
    return ApiService.get(ProductPath.FIND_BY_CATEGORY_ID, categoryId);
  },
  setTopProduct(productId, topProduct) {
    return ApiService.put(`${ProductPath.SET_TOP_PRODUCT}?id=${productId}&topProduct=${topProduct}`)
  },
  findTopProducts() {
    return ApiService.get(ProductPath.FIND_TOP_PRODUCTS);
  },
  searchProductByNameInNavibar(name) {
    return ApiService.query(`${ProductPath.SEARCH_PRODUCT_NAVIBAR}?name=${name}`)
  },
  searchProductByNameInPage(name) {
    return ApiService.query(`${ProductPath.SEARCH_PRODUCT_IN_PAGE}?name=${name}`)
  },
  findByRoomId(roomId) {
    return ApiService.get(ProductPath.FIND_BY_ROOM_ID, roomId);
  },
  getRelativeProducts(id) {
    return ApiService.get(ProductPath.GET_RELATIVE_PRODUCTS, id);
  }
};

export const OpportunityService = {
  create: function (data) {
    return ApiService.post(OpportunityPath.CREATE_OPPORTUNITY, data);
  },
  findAll() {
    return ApiService.get(Path.OPPORTUNITY);
  },
  findById(id) {
    return ApiService.get(OpportunityPath.FIND_BY_ID, id)
  },
  deleteById(id) {
    return ApiService.delete(Path.OPPORTUNITY, id);
  },
  updateStatus(status) {
    return ApiService.put(OpportunityPath.UPDATE_STATUS, status);
  },
  updateOpportunityCancelStatus(cancelStatus) {
    return ApiService.put(OpportunityPath.UPDATE_CANCEL, cancelStatus);
  }
};

export const OrganizationService = {
  save(data) {
    return ApiService.post(Path.ORGANIZATION_SETTINGS, data)
  },
  getOrganizationSettings() {
    return ApiService.get(Path.ORGANIZATION_SETTINGS)
  }
};

export const IdeaService = {
  save(data) {
    return ApiService.post(Path.IDEA, data);
  },
  findAll() {
    return ApiService.get(Path.IDEA);
  },
  findById(id) {
    return ApiService.get(IdeaPath.FIND_BY_ID, id)
  },
  deleteById(id) {
    return ApiService.delete(Path.IDEA, id);
  },
  updateStatus(status) {
    return ApiService.put(Path.IDEA, status);
  },
  findIdeasHomePage() {
    return ApiService.get(IdeaPath.IDEAS_HOMEPAGE)
  }
};

export const CustomerService = {
  getList(page=1, query="") {
    return ApiService.query(`${CustomerPath.GENERAL}?page=${page}&query=${query}`);
  },
  findById(id) {
    return ApiService.get(CustomerPath.GENERAL, id)
  },
  updateContact(id, data) {
    return ApiService.update(CustomerPath.GENERAL, id, data)
  },
  markResolve(id) {
    return ApiService.update(CustomerPath.MARK_RESOLVED, id)
  },
  deleteById(id) {
    return ApiService.delete(CustomerPath.GENERAL, id);
  }
};

export const AuthService = {
  post(credentials) {
    return ApiService.post(Path.LOGIN, credentials);
  }
};