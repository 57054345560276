var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"30px"}},[_c('router-link',{attrs:{"to":"/admin/categories"}},[_c('a-button',{staticClass:"d-flex justify-content-center mr-3",attrs:{"type":"primary","shape":"circle","icon":"left","size":"small"}})],1),_c('h1',{staticClass:"mb-0",staticStyle:{"font-size":"1.5rem"}},[_vm._v("\n      "+_vm._s(_vm.pageHeader)+"\n    ")])],1),_c('div',[_c('a-form',{attrs:{"form":_vm.categoryForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"Tên danh mục"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  validator: _vm.checkNameExisted,
                },
                {
                  required: true,
                  message: 'Vui lòng nhập tên danh mục sản phẩm!'
                }
              ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  validator: checkNameExisted,\n                },\n                {\n                  required: true,\n                  message: 'Vui lòng nhập tên danh mục sản phẩm!'\n                }\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Tên danh mục sản phẩm!"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"Mô tả danh mục"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description'
          ]),expression:"[\n            'description'\n          ]"}],attrs:{"placeholder":"Mô tả danh mục","rows":4}})],1),_c('a-form-item',{staticClass:"mb-2",attrs:{"label":"Ảnh mô tả","label-col":{ span: 5 },"wrapper-col":{ span: 18 },"required":"true"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUploadThumbnail},on:{"change":_vm.handleChangeThumbnail}},[(_vm.categoryThumbnail)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.imageUrl,"alt":"thumbnail"}}):_vm._e(),(!_vm.categoryThumbnail && _vm.category.thumbnail)?_c('img',{staticClass:"category-thumbnail",attrs:{"src":_vm.category.thumbnail,"alt":"thumbnail"}}):_vm._e(),(!_vm.categoryThumbnail && !_vm.category.thumbnail)?_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n              Upload\n            ")])],1):_vm._e()])],1),_c('a-form-item',_vm._b({attrs:{"label":"Danh mục cha"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'parentCategoryId',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng chọn danh mục cha!',
                } ],
            } ]),expression:"[\n            'parentCategoryId',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Vui lòng chọn danh mục cha!',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"300px"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("\n            Danh mục chính\n          ")]),_vm._l((_vm.loadCategories),function(category){return _c('a-select-option',{key:category.id},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])})],2)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("\n          Lưu danh mục\n        ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }