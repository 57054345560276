<template>
  <section class="bg-white room-feature-wrapper">
    <div class="row">
      <div class="col-md-6 col-12 p-0">
        <app-feature-category
          :category="categories[0]"
          level="top-feature"
        ></app-feature-category>
      </div>
      <div class="col-md-6 col-12 p-0">
        <div class="row m-0">
          <div class="col-12 p-0">
            <app-feature-category
              :category="categories[1]"
              level="second-feature"
            ></app-feature-category>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-md-6 col-sm-6 col-12 p-0 pl-0">
            <app-feature-category
              :category="categories[2]"
              level="third-feature"
            ></app-feature-category>
          </div>
          <div class="col-md-6 col-sm-6 col-12 p-0 pr-0">
            <app-feature-category
              :category="categories[3]"
              level="third-feature"
            ></app-feature-category>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FeatureCategory from "./FeatureCategory"

export default {
  name: "FeatureCategories",
  components: {
    appFeatureCategory: FeatureCategory
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .room-feature-wrapper {

  }

  .feature-content {
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-start; */
    flex-direction: column;
    height: 100%;

    h2, h3, h4 {
      z-index: 1;
    }
  }
</style>