<template>
  <a-layout
    id="components-layout-demo-side"
    style="min-height: 100vh"
  >
    <a-layout-sider
      v-model="collapsed"
      collapsible=""
    >
      <a-affix>
        <div
          class="logo nuvola-text-uppercase nuvola-bg-transparent nuvola-cursor-pointer text-center"
          @click="navigateTo('')"
        >
          <span class="text-white font-weight-bold nuvola-font-size-20">NUVOLA</span>
        </div>
        <a-menu
          theme="dark"
          :selected-keys="menuKey"
          :default-open-keys="['productSub']"
          mode="inline"
        >
          <a-menu-item
            key="dashboard" class="d-flex align-items-center"
            @click="navigateTo('dashboard')"
          >
            <a-icon type="home" />
            <span>Trang chính</span>
          </a-menu-item>
          <a-menu-item
            key="opportunity" class="d-flex align-items-center"
            @click="navigateTo('opportunities')"
          >
            <a-icon type="project" />
            <span>Tiềm năng</span>
          </a-menu-item>
          <a-menu-item
            key="customer" class="d-flex align-items-center"
            @click="navigateTo('customers')"
          >
            <a-icon type="user" />
            <span>Khách hàng</span>
          </a-menu-item>
          <a-menu-item
            key="room" class="d-flex align-items-center"
            @click="navigateTo('rooms')"
          >
            <a-icon type="layout" />
            <span>Loại phòng</span>
          </a-menu-item>
          <a-sub-menu key="productSub">
            <span slot="title" class="d-flex align-items-center">
              <a-icon type="shopping" /><span>Sản phẩm</span>
            </span>
            <a-menu-item
              key="category" class="d-flex align-items-center"
              @click="navigateTo('categories')"
            >
              <a-icon type="appstore" class="mr-1" />
              <span>Loại sản phẩm</span>
            </a-menu-item>
            <a-menu-item key="product" @click="navigateTo('products')">
              Quản lý sản phẩm
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item
            key="idea" class="d-flex align-items-center"
            @click="navigateTo('ideas')"
          >
            <a-icon type="file-text" />
            <span>Bài viết</span>
          </a-menu-item>
          <a-menu-item
            key="common-settings" class="d-flex align-items-center"
            @click="navigateTo('common-settings')"
          >
            <a-icon type="setting" />
            <span>Tùy chỉnh</span>
          </a-menu-item>
        </a-menu>
      </a-affix>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <div class="h-100 pr-4 float-right">
          <span class="font-weight-bold">{{ currentUser.username }} </span><a @click="logout">(Đăng xuất)</a>
        </div>
      </a-layout-header>
      <a-layout-content style="margin: 16px 16px">
        <div
          class="bg-white p-4"
          style="min-height: 100%"
        >
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        Nuvola ©2022 Created by CSH Tech
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "../../constants/action-types";
import {MENU_KEY} from "../../constants/const";

export default {
  name: "Admin",
  component: {},
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    ...mapGetters(
      [
        "menuKey",
      ]
    )
  }, methods: {
    logout() {
      this.$store.dispatch(ActionTypes.LOGOUT).then(() => this.$router.push({name: "login"}));
    },
    navigateTo(path) {
      this.$router.push(`/admin/${path}`).catch(error => {
      });
    }
  },
};
</script>

<style>
  #components-layout-demo-side .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
</style>