import {OpportunityService} from "../services/api-service";
import {getStatusObject} from "../../utils/common-utils";
import Vue from "vue";

const state = {
  opportunities: [],
  opportunity: null
};

const mutations = {
  setOpportunity(state, opportunity) {
    state.opportunity = opportunity;
  },
  setOpportunities(state, opportunities) {
    Vue.set(state, 'opportunities', opportunities);
  },
};

const actions = {
  fetchOpportunities(context) {
    OpportunityService.findAll().then(res => {
      context.commit("setOpportunities", res.data);
    }).catch(error => {
      console.log(error);
    })
  },
  async fetchOpportunity(context, slug) {
    await OpportunityService.findById(slug).then(res => {
      context.commit("setOpportunity", res.data);
    }).catch(error => {
      console.log(error);
    })
  },
  setOpportunities(context, data) {
    context.commit("setOpportunities", data);
  }
};

const getters = {
  opportunities: state => {
    state.opportunities.map(opportunity => {
      opportunity.statusObject = getStatusObject(opportunity.status);
      return opportunity;
    });
    return state.opportunities;
  },
  opportunity: state => {
    if (state.opportunity) {
      state.opportunity.statusObject = getStatusObject(state.opportunity.status);
      return state.opportunity;
    }
    return state.opportunity;
  }
};

export const opportunity = {
  state,
  mutations,
  actions,
  getters
};
