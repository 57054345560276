<template>
  <div>
    <app-images-slider :items="items"></app-images-slider>
    <div class="main-container bg-white">
      <!-- BEGIN TOP CATEGORIES -->
      <app-top-categories></app-top-categories>
      <a-divider></a-divider>
      <!-- END TOP CATEGORIES -->

      <!-- BEGIN FEATURE PRODUCTS -->
      <div class="m-auto p-4">
        <h3 class="text-center">
          Sản phẩm nổi bật
        </h3>
        <app-products-slider :product-items="topProducts"></app-products-slider>
      </div>
      <a-divider></a-divider>
      <!-- END FEATURE PRODUCTS -->

      <!-- BEGIN IDEA LIST -->
      <div class="m-auto p-4">
        <h3 class="text-center">
          Xu hướng mới nhất
        </h3>
        <app-idea-list :ideas="ideas"></app-idea-list>
      </div>
      <a-divider></a-divider>
      <!-- END IDEA LIST -->

      <!-- BEGIN COMMITMENT -->
      <div class="ctn-item promise">
        <h3 class="text-center">
          Nuvola cam kết
        </h3>
        <div class="row m-0 w-100 nuvola-essentials">
          <div
            v-for="(item, index) in essentials" :key="index"
            class="col-4 col-md-2 text-center"
          >
            <img :src="item.icon" :alt="item.label">
            <div>
              <span class="font-weight-bold text-nuvola-color-dark-blue d-inline-block">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <!-- END COMMITMENT -->

      <!-- BEGIN EXPLORE CATEGORIES -->
      <div class="ctn-item about-ctn text-center">
        <h3 class="text-center">
          Khám phá nội thất của chúng tôi
        </h3>
        <div class="url-list">
          <template v-for="(item, index) in urls">
            <span
              :key="index" class="nuvola-cursor-pointer font-weight-bold"
              @click="navigateTo('category', item.id)"
            >
              {{ item.name }}
              <span v-if="index < urls.length - 1" :key="index + urls.length">  |  </span>
            </span>
          </template>
        </div>
      </div>
      <!-- BEGIN EXPLORE CATEGORIES -->
    </div>
  </div>
</template>

<script>
  import TopCategories from "../../components/main-page/TopCategories";
  import IdeaList from "../../components/main-page/IdeaSlider";
  import ImagesSlider from "../../components/main-page/ImagesSlider";
  import ProductsSlider from "../../components/main-page/ProductsSlider";
  import {productItems} from "../../store/dummy/products";
  import {mapGetters} from "vuex";
  import {store} from "@/store";
  import {essentials} from "../../store/dummy/nuvola-essentials"

  async function initData(to, next) {
    await store.dispatch("fetchTopProducts");
    await store.dispatch("fetchIdeaHomepage");
    return next();
  }

  export default {
    components: {
      appTopCategories: TopCategories,
      appIdeaList: IdeaList,
      appImagesSlider: ImagesSlider,
      appProductsSlider: ProductsSlider
    },
    async beforeRouteUpdate(to, from, next) {
      return await initData(to, next);
    },
    async beforeRouteEnter(to, from, next) {
      return await initData(to, next);
    },
    data() {
      return {
        productItems,
        items: {
          leftSlider: [
            {
              src: require("@/assets/images/index-slider/1.jpg")
            },
            {
              src: require("@/assets/images/index-slider/2.jpg")
            },
            {
              src: require("@/assets/images/index-slider/3.jpg")
            },
            {
              src: require("@/assets/images/index-slider/4.jpg")
            },
            {
              src: require("@/assets/images/index-slider/5.jpg")
            }
          ],
          rightSlider: [
            {
              src: require("@/assets/images/index-slider/right/right1.png")
            },
            {
              src: require("@/assets/images/index-slider/right/right2.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right3.png")
            },
            {
              src: require("@/assets/images/index-slider/right/right4.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right5.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right6.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right7.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right8.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right9.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right10.jpg")
            },
            {
              src: require("@/assets/images/index-slider/right/right11.png")
            },
            {
              src: require("@/assets/images/index-slider/right/right12.png")
            }
          ]
        },
        essentials
      };
    },
    computed: {
      ...mapGetters(
        [
          "topProducts",
          "loadCategoryMenu",
          "ideas"
        ]
      ),
      urls() {
        return this.loadCategoryMenu.map(category => {
          return {
            id: category.id,
            name: category.name
          }
        })
      }
    },
    methods: {
      navigateTo(path, slug) {
        this.$router.push(`/${path}/${slug}`).catch(error => {
        });
      }
    }
  };
</script>

<style lang="scss">
    @import "../../static/scss/index";

    .ant-carousel > > > .slick-slide {
        text-align: center;
        height: 160px;
        line-height: 160px;
        background: #364d79;
        overflow: hidden;
    }

    .ant-carousel > > > .slick-slide h3 {
        color: #fff;
    }

    .nuvola-essentials {
        img {
            max-width: 100px;
            width: 100%;
            height: auto;
        }
    }
</style>
