<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <router-link to="/admin/customers">
        <a-button
          type="primary" shape="circle"
          icon="left"
          size="small" class="d-flex justify-content-center mr-3"
        />
      </router-link>
      <h1 style="font-size: 1.5rem" class="mb-0">
        Chi tiết khách hàng
      </h1>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-7">
        <div class="shadow nuvola-border-radius-10 p-4 customer--basic-box">
          <a-form
            :form="customerBasicInfoForm" :hide-required-mark="true"
            @submit="handleSubmit"
          >
            <div class="d-flex">
              <h3 class="d-flex align-items-center text-truncate mr-auto w-100" style="line-height: 40px;">
                <a-icon type="user" class="mr-2" />
                <span v-if="!editCustomer">{{ getFieldValue('name') }}</span>
                <a-form-item class="mb-0 w-100" :class="editCustomer ? 'd-block' : 'd-none'">
                  <a-input
                    v-decorator="['name', { rules: [{ required: true, message: 'Vui lòng nhập tên khách hàng!' }] }]"
                  />
                </a-form-item>
              </h3>
              <div class="d-flex">
                <a-button
                  v-if="!editCustomer" type="link"
                  class="text-black"
                  @click="editCustomer = true"
                >
                  <a-icon type="edit" />
                </a-button>
                <a-button
                  v-if="editCustomer" type="link"
                  class="nuvola-text-color-success px-2"
                  @click="handleSubmit"
                >
                  <a-icon type="check-circle" />
                </a-button>
                <a-button
                  v-if="editCustomer" type="link"
                  class="nuvola-text-color-danger px-2"
                  @click="editCustomer = false"
                >
                  <a-icon type="close-circle" />
                </a-button>
              </div>
            </div>

            <a-divider></a-divider>
            <div class="customer--basic-info-form">
              <a-form-item
                :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                class="mb-0"
              >
                <span slot="label">
                  <a-icon type="phone" /> Số điện thoại
                </span>
                <a-input
                  v-decorator="['phoneNumber', { rules: [{ required: true, message: 'Vui lòng nhập số điện thoại!' }] }]"
                  :class="editCustomer ? 'd-block' : 'd-none'"
                />
                <span v-if="!editCustomer" class="font-weight-bold">{{ getFieldValue('phoneNumber') }}</span>
              </a-form-item>
              <a-form-item
                :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                class="mb-0"
              >
                <span slot="label">
                  <a-icon type="mail" /> Email
                </span>
                <a-input
                  v-decorator="['email']"
                  :class="editCustomer ? 'd-block' : 'd-none'"
                />
                <span v-if="!editCustomer" class="font-weight-bold">{{ getFieldValue('email') }}</span>
              </a-form-item>
              <a-form-item
                :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                class="mb-0"
              >
                <span slot="label">
                  <a-icon type="sync" /> Trạng thái
                </span>
                <div v-if="customer.status === 1">
                  <a-tag
                    color="#ACE539"
                    disabled class="nuvola-cursor-default"
                  >
                    <div class="d-flex align-items-center">
                      <a-icon type="check-circle" class="mr-1" /> Đã tư vấn
                    </div>
                  </a-tag>
                </div>
                <div v-else>
                  <a-tag
                    color="#D9AF00"
                    disabled class="nuvola-cursor-default"
                  >
                    <div class="d-flex align-items-center">
                      <a-icon type="exclamation-circle" class="mr-1" /> Đang chờ tư vấn
                    </div>
                  </a-tag>
                  <a-button type="link" @click="showConfirm">
                    <a-icon type="check-circle" />
                  </a-button>
                </div>
              </a-form-item>
            </div>
          </a-form>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="shadow nuvola-border-radius-10 p-4 customer--basic-box">
          <h4>Địa chỉ</h4>
          <template v-if="customer.customerAddresses && customer.customerAddresses.length > 0">
            <template v-for="(address, index) in customer.customerAddresses">
              <div :key="index" class="mb-2">
                <app-customer-address :address="address"></app-customer-address>
              </div>
            </template>
          </template>
          <template v-else>
            <a-empty></a-empty>
          </template>
        </div>
      </div>
      <div class="col-12">
        <div class="shadow nuvola-border-radius-10 p-4">
          <h4>Danh sách cơ hội</h4>
          <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="customer.opportunities"
          >
            <span
              slot="opportunityName" slot-scope="text, item"
              class="d-flex align-items-center"
            >
              {{ item.name }}
            </span>
            <span slot="product" slot-scope="text, item">
              {{ item.productItem.name }}
            </span>
            <div slot="status" slot-scope="text, item">
              <a-tag :color="item.statusObject.color">
                {{ item.statusObject.label }}
              </a-tag>
            </div>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerAddressComponent from "./CustomerAddressComponent";
import {store} from "@/store";
import {mapGetters} from "vuex";
import {isBlank} from "../../../utils/common-utils"
import {CustomerService} from "../../../store/services/api-service";

const columns = [
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {customRender: 'opportunityName'},
    width: '30%',
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'product',
    key: 'product',
    scopedSlots: {customRender: 'product'},
    width: '30%',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: {customRender: 'status'},
    width: '30%',
  }
];
export default {
  name: "CustomerDetail",
  components: {
    appCustomerAddress: CustomerAddressComponent
  },
  data() {
    return {
      editCustomer: false,
      setFormValue: false,
      columns
    }
  },
  computed: {
    ...mapGetters([
      "customer"
    ]),
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.slug !== undefined) {
      try {
        await store.dispatch('fetchCustomer', to.params.slug);
        return next();
      }catch (e) {
        return next('/404');
      }
    }
    return next('/404');
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.slug !== undefined) {
      try {
        await store.dispatch('fetchCustomer', to.params.slug);
        return next();
      }catch (e) {
        return next('/404');
      }
    }
    return next('/404');
  },
  beforeCreate() {
    this.customerBasicInfoForm = this.$form.createForm(this, {
      name: 'customerBasicInfoForm',
    });
  },
  mounted() {
    this.customerBasicInfoForm.setFieldsValue({
      name: this.customer.name,
      phoneNumber: this.customer.phoneNumber,
      email: this.customer.email
    });
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch('fetchCustomer', this.$route.params.slug);
      this.customerBasicInfoForm.setFieldsValue({
        name: this.customer.name,
        phoneNumber: this.customer.phoneNumber,
        email: this.customer.email
      });
      this.loading = false;
    },
    handleSubmit(e) {
      let self = this;
      e.preventDefault();
      this.customerBasicInfoForm.validateFields(async (err, values) => {
        if (err) {
          return
        }
        try {
            await CustomerService.updateContact(self.customer.id, values);
            let customerPayload = {
                ...this.customer,
                ...values
            };
            self.$store.dispatch('updateCustomer', customerPayload);
            self.$message.success('Cập nhật thông tin khách hàng thành công!');
        } catch (e) {
            console.log(e);
            self.$message.error('Có lỗi xảy ra! Vui lòng thử lại sau.');
        }
        this.editCustomer = false;
      });
    },
    showConfirm: function () {
      const that = this;
      this.$confirm({
        title: 'Bạn tư vấn xong cho khách hàng này?',
        content: 'Thao tác này sẽ chuyển tất cả Cơ hội của khách hàng này về trạng thái "Đã tư vấn".',
        okType: 'warning',
        okText: 'Đã tư vấn',
        cancelText: 'Hủy',
        onOk() {
          that.changeCustomerStatus()
        }
      });
    },
    async changeCustomerStatus() {
      var self = this;
      try {
        await CustomerService.markResolve(self.customer.id);
        await self.fetchData();
        self.$message.success('Cập nhật trạng thái khách hàng thành công!');
      } catch (e) {
        console.log(e);
        self.$message.error('Có lỗi xảy ra! Vui lòng thử lại sau.');
      }
    },
    getFieldValue(field) {
      if (isBlank(field)) {
        return;
      }
      return this.customerBasicInfoForm.getFieldValue(field);
    }
  },
}
</script>

<style lang="scss">
  .customer--basic-box {
    min-height: 300px !important;
    max-height: 300px !important;
    overflow-y: auto;
  }

  .customer--basic-info-form {
    .ant-form-item-label label {
      float: left;

      &::after {
        content: "";
      }
    }
  }
</style>