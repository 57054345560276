<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <router-link to="/admin/rooms">
        <a-button
          type="primary" shape="circle"
          icon="left"
          size="small" class="d-flex justify-content-center mr-3"
        />
      </router-link>
      <h1 style="font-size: 1.5rem" class="mb-0">
        {{ pageHeader }}
      </h1>
    </div>
    <a-form :form="roomForm" @submit="handleSubmit">
      <a-form-item v-bind="formItemLayout" label="Tên loại phòng">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                {
                  validator: checkNameValid
                },
                {
                  required: true,
                  message: 'Vui lòng nhập tên loại phòng!',
                },
              ],
            },
          ]"
          placeholder="Tên loại phòng!"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Mô tả">
        <a-textarea
          v-decorator="[
            'description',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng nhập mô tả!',
                },
              ],
            },
          ]"
          rows="4"
          placeholder="Mô tả loại phòng!"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Các loại sản phẩm">
        <a-select
          v-decorator="['categoryIds']"
          class="w-100"
          mode="multiple"
          style="width: 200px"
          placeholder="Vui lòng chọn các loại sản phẩm thuộc loại phòng này!"
        >
          <a-select-option v-for="category in loadCategories" :key="category.id">
            {{ category.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        class="mb-2" label="Ảnh mô tả"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        required="true"
      >
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUploadThumbnail"
          @change="handleChangeThumbnail"
        >
          <img
            v-if="file" :src="imageUrl"
            alt="thumbnail" class="category-thumbnail"
          >
          <img
            v-if="!file && room.thumbnail" :src="room.thumbnail"
            alt="thumbnail" class="category-thumbnail"
          >
          <div v-if="!file && !room.thumbnail">
            <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button
          type="primary" html-type="submit"
          :loading="saving"
        >
          Lưu loại phòng
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  import {ActionTypes} from "../../../constants/action-types";
  import {mapGetters} from "vuex";
  import {RoomsService, UploadService} from "../../../store/services/api-service";
  import {store} from "@/store";
  import {BucketType} from "../../../constants/const"
  import {isBlank, replaceAll} from "../../../utils/common-utils";

  const VUE_APP_API_URL = process.env.VUE_APP_API_URL;
  let isEdit = false;
  let originName = "";
  let timer = null;

  function getImgSource(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  export default {
    name: "CreateRoom",
    async beforeRouteUpdate(to, from, next) {
      await store.dispatch("resetRoomState");
      return next();
    },
    async beforeRouteEnter(to, from, next) {
      await store.dispatch("resetRoomState");
      if (to.params.slug !== undefined) {
        await store.dispatch(
          "fetchRoom",
          to.params.slug
        );
        isEdit = true;
        originName = store.getters.room.name;
      } else {
        isEdit = false;
        originName = "";
      }
      return next();
    },
    data() {
      return {
        formItemLayout: {
          labelCol: {
            xs: {span: 24},
            sm: {span: 5},
          },
          wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
          },
        },
        imageUrl: '',
        uploadLoading: false,
        file: null,
        saving: false
      }
    },
    computed: {
      ...mapGetters(
        [
          "room",
          "loadCategories"
        ]
      ),
      pageHeader() {
        return isEdit ? "Cập nhật loại phòng" : "Tạo loại phòng"
      }
    },
    beforeCreate() {
      this.roomForm = this.$form.createForm(this, {
        name: 'roomForm',
      });
    },
    mounted() {
      this.roomForm.setFieldsValue({
        name: this.room.name,
        description: this.room.description,
        categoryIds: this.room.categoryIds
      });
      this.$store.dispatch(ActionTypes.LOAD_CATEGORIES);
    },
    methods: {
      saveRoom: function (roomPayload) {
        if (isEdit) {
          RoomsService.update(roomPayload, this.room.id).then(() => {
            this.$router.push("/admin/rooms")
          }).catch(error => {
            this.$message.error(error);
            console.log(error);
          });
        } else {
          RoomsService.create(roomPayload).then(() => {
            this.$router.push("/admin/rooms")
          }).catch(error => {
            this.$message.error(error);
            console.log(error);
          });
        }
      },
      handleSubmit(e) {
        e.preventDefault();
        this.saving = true;
        this.roomForm.validateFields((err, values) => {
          if (err) {
            this.saving = false;
            return;
          }
          if (!this.room.thumbnail && !this.file) {
            this.$message.error('Vui lòng đăng ảnh mô tả!');
            this.saving = false;
            return;
          }
          let roomPayload = {
            ...this.room,
            ...values,
            thumbnail: this.room.thumbnail
          };
          if (!this.file) {
            this.saveRoom(roomPayload);
            return;
          }
          UploadService.uploadSingle(BucketType.ROOM, this.file).then(res => {
            this.saveRoom({
              ...roomPayload,
              thumbnail: res.data.path
            });
          }).catch(error => {
            this.$message.error(error);
            console.log(error);
          })
        });
      },
      beforeUploadThumbnail() {
        return false;
      },
      handleChangeThumbnail(info) {
        console.log(info);
        const file = info.file;
        const isValidImageType = (file.type === 'image/jpeg') || (file.type === 'image/png');
        if (!isValidImageType) {
          this.$message.error('You can only upload JPG or PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('Image must smaller than 1MB!');
        }
        if (isValidImageType && isLt5M) {
          this.file = file;
          getImgSource(file, imageUrl => {
            this.imageUrl = imageUrl;
          });
        }
      },
      checkNameValid: (rule, value, callback) => {
        if (isBlank(value) || (isEdit && originName === value)) {
          callback();
        }
        return new Promise(function (resolve) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          timer = setTimeout(function () {
            RoomsService.checkNameValid({
              name: value
            }).then(function (res) {
              resolve(res.data.nameValid);
            }).catch(function (error) {
              resolve(false);
            });
          }, 1000);
        }).then((result) => {
          if (result) {
            callback();
          } else {
            callback('Tên danh mục đã được sử dụng!');
          }
        }).catch((error) => {
          this.$message.error(`Có lỗi xảy ra! ${error}`);
        });
      }
    },
    async beforeRouteLeave(to, from, next) {
      await this.$store.dispatch("resetRoomState");
      next();
    }
  }
</script>

<style scoped>

</style>