<template>
  <div
    class="border border-light shadow nuvola-border-radius-10 text-center p-4 nuvola-cursor-pointer mx-2"
    :style="{backgroundColor, width: '300px'}"
  >
    <div>
      <a-icon
        style="font-size: 40px"
        :type="icon"
      />
      <h4 class="pt-4">
        {{ label }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingItem",
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: "setting"
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white"
    }
  }
}
</script>

<style scoped>

</style>