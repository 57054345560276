const state = {
 menuKey: ""
};

const mutations = {
  setMenuKey(state, menuKey) {
    state.menuKey = menuKey;
  }
};

const actions = {
  setAdminMenuKey(context, menuKey) {
    context.commit("setMenuKey", menuKey);
  }
};

const getters = {
  menuKey: state => {
    return [state.menuKey];
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};