<template>
  <div class="d-block">
    <div v-if="productItems.length > 0" class="product-slider">
      <slick
        ref="slick"
        :options="slickOptions"
      >
        <div
          v-for="(productItem, index) in productItems" :key="index"
          class="d-flex justify-content-center"
        >
          <app-product-item :product-item="productItem"></app-product-item>
        </div>
      </slick>
    </div>
    <div v-else>
      <a-empty></a-empty>
    </div>
  </div>
</template>

<script>
import ProductItem from "./ProductItem";
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
  name: "ProductsSlider",
  components: {
    appProductItem: ProductItem,
    Slick
  },
  props: {
    productItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      slickOptions: {
        //options can be used from the plugin documentation
        infinite: true,
        accessibility: true,
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        edgeFriction: 0.30,
        swipe: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
    });
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
}
</script>

<style lang="scss">
  .product-slider {
    .slick-prev:before, .slick-next:before {
      color: black;
    }

    .slick-dots li button:before {
      font-size: 12px;
    }
  }

</style>