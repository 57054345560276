<template>
  <div>
    <div v-if="products && products.length > 0" class="mt-4">
      <div>
        <span class="ml-4"><span class="font-weight-bold nuvola-font-size-20">{{ products.length }}</span> sản phẩm</span>
      </div>
      <div class="row w-100 m-0">
        <div
          v-for="(productItem, index) in products" :key="`categoryDetailsProducts-${index}`"
          class="col-md-4 col-sm-4 col-6"
        >
          <app-product-item :product-item="productItem"></app-product-item>
        </div>
      </div>
    </div>
    <div v-else class="nuvola--mt-48">
      <a-empty></a-empty>
    </div>
  </div>
</template>

<script>
import ProductItem from "./ProductItem";

export default {
  name: "ProductList",
  components: {
    appProductItem: ProductItem
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>