<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container">
      <h1 class="text-center">
        {{ room.name }}
      </h1>
      <div class="text-center">
        <span class="font-weight-bold">{{ room.name }}:
          <template v-for="(item, idx) in room.categories">
            <span
              :key="idx" class="nuvola-cursor-pointer font-weight-bold"
              @click="navigateTo('category', item.id)"
            >
              {{ item.name }}
              <span v-if="idx < room.categories.length - 1" :key="idx + room.categories.length">  |  </span>
            </span>
          </template>
        </span>
      </div>

      <!-- BEGIN FEATURE CATEGORIES -->
      <div class="nuvola--mt-48 nuvola--mb-48">
        <app-feature-categories :categories="room.categories"></app-feature-categories>
        <a-divider></a-divider>
      </div>
      <!-- END FEATURE CATEGORIES -->

      <!-- BEGIN TOP SALE PRODUCTS -->
      <section v-show="roomDetailsProductsData.topProducts" class="nuvola--mt-32">
        <div class="text-center">
          <h3>Sản phẩm bán chạy nhất</h3>
        </div>
        <app-products-slider :product-items="roomDetailsProductsData.topProducts"></app-products-slider>
        <a-divider></a-divider>
      </section>
      <!-- END TOP SALE PRODUCTS -->

      <!-- BEGIN DESIGN IDEAS -->
      <!--      <section class="nuvola&#45;&#45;mt-32">-->
      <!--        <div class="text-center">-->
      <!--          <h3>Ý tưởng thiết kế</h3>-->
      <!--        </div>-->
      <!--        <a-divider></a-divider>-->
      <!--      </section>-->
      <!-- END DESIGN IDEAS -->

      <!-- BEGIN NEW PRODUCTS IN ROOM -->
      <section v-show="roomDetailsProductsData.newProducts" class="nuvola--mt-32">
        <div class="text-center">
          <h3>Sản phẩm mới nhất</h3>
        </div>
        <app-products-slider :product-items="roomDetailsProductsData.newProducts"></app-products-slider>
      </section>
      <!-- END NEW PRODUCTS IN ROOM -->
    </div>
  </div>
</template>

<script>
import FeatureCategories from "../../components/main-page/room/FeatureCategories"
import ProductsSlider from "../../components/main-page/ProductsSlider";
import {mapGetters} from "vuex";
import {store} from "@/store";

async function initData(to, from, next) {
    await store.dispatch(
        "fetchRoom",
        to.params.slug
    );
    await store.dispatch(
        "fetchRoomDetailProducts",
        to.params.slug
    );
    return next();
}

export default {
    name: "RoomDetails",
    components: {
        appFeatureCategories: FeatureCategories,
        appProductsSlider: ProductsSlider
    },
    async beforeRouteEnter(to, from, next) {
      return await initData(to, from, next);
    },
    async beforeRouteUpdate(to, from, next) {
        return await initData(to, from, next);
    },
    computed: {
        ...mapGetters(
            [
                "room",
                "roomDetailsProductsData"
            ]
        ),
    },
    methods: {
        navigateTo(path, slug) {
            this.$router.push(`/${path}/${slug}`).catch(error => {});
        }
    }
}
</script>

<style lang="scss" scoped>

</style>