<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container">
      <h1 class="text-center">
        {{ category.name }}
      </h1>
      <div v-if="category.subCategories && category.subCategories.length > 0" class="m-auto list-sub-category">
        <div class="row text-center">
          <div
            v-for="(category, index) in category.subCategories" :key="`subCategories-${index}`"
            class="col-6 col-sm-4 text-truncate p-1"
          >
            <router-link :to="`/category/${category.id}`">
              <span class="nuvola-cursor-pointer">{{ category.name }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <app-product-list :products="categoryDetailsProducts"></app-product-list>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {store} from "@/store";
import ProductList from "../../components/main-page/ProductList";
import {ActionTypes} from "../../constants/action-types";
import {formatCurrency} from "../../utils/common-utils";

async function init(to, next) {
  if (to.params.slug !== undefined) {
    await store.dispatch(
            "fetchCategoryDetailsProducts",
            to.params.slug
    );
    await store.dispatch(
            "fetchCategory",
            to.params.slug
    );
  }
  return next();
}

export default {
  name: "CategoryDetails",
  components: {
    appProductList: ProductList
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(
      [
        "category",
        "categoryDetailsProducts",
        "fetchError"
      ]
    )
  },
  async beforeRouteUpdate(to, from, next) {
    return await init(to, next);
  },
  async beforeRouteEnter(to, from, next) {
    return await init(to, next);
  },
  mounted() {
    window.scrollTo(0,0);
  },
}
</script>

<style lang="scss" scoped>
  .list-sub-category {
    width: 80%;
    a {
      color: black;
    }
  }
  .product-item-card-wrapper {
    display: flex;
    justify-content: center;
    width: 33%;
  }
</style>