<template>
  <div class="opportunity-detail-wrapper">
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <div class="d-flex align-items-center mr-auto">
        <router-link to="/admin/opportunities">
          <a-button
            type="primary" shape="circle"
            icon="left"
            size="small" class="d-flex justify-content-center mr-3"
          />
        </router-link>
        <h1 style="font-size: 1.5rem" class="mb-0">
          Chi tiết đơn hàng tiềm năng
        </h1>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12">
        <a-alert
          v-if="opportunity.cancel"
          message="Đơn hàng tiềm năng đã bị hủy"
          type="error"
          class="mb-4"
        />
        <div class="shadow nuvola-border-radius-10 p-4">
          <div class="row">
            <div class="col-3 d-flex justify-content-center flex-column align-items-center border-right">
              <div class="rounded-circle category--thumbnail" :style="{ 'background-image': 'url(' + opportunity.productItem.category.thumbnail + ')' }">
              </div>
              <h6>{{ opportunity.productItem.category.name }}</h6>
            </div>
            <div class="col-9">
              <div class="mb-4">
                <a-steps
                  :current="opportunity.status" size="small"
                  :status="opportunity.cancel ? 'error' : 'process'"
                >
                  <a-step
                    title="Mới" class="nuvola-cursor-pointer"
                    @click="changeStatus(OPPORTUNITY_STATUSES.NEW)"
                  ></a-step>
                  <a-step
                    title="Đang xử lý" class="nuvola-cursor-pointer"
                    @click="changeStatus(OPPORTUNITY_STATUSES.PROCESSING)"
                  ></a-step>
                  <a-step
                    title="Đã xử lý xong" class="nuvola-cursor-pointer"
                    @click="changeStatus(OPPORTUNITY_STATUSES.RESOLVED)"
                  ></a-step>
                </a-steps>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-auto">
                  <h5>
                    {{ opportunity.name }}
                  </h5>
                </div>
                <div>
                  <a-dropdown placement="bottomLeft" :trigger="['click']">
                    <a-button
                      shape="circle" icon="ellipsis"
                      class="d-flex align-items-center justify-content-center"
                    />
                    <a-menu slot="overlay">
                      <a-menu-item v-if="!opportunity.cancel" @click="updateOpportunityCancelStatus(true)">
                        <a-icon type="close" /> Hủy tiềm năng
                      </a-menu-item>
                      <a-menu-item v-if="opportunity.cancel" @click="updateOpportunityCancelStatus(false)">
                        <a-icon type="close" /> Mở lại tiềm năng
                      </a-menu-item>
                      <a-menu-item @click="showConfirm(opportunity)">
                        <a-icon type="delete" /> Xóa tiềm năng
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </div>
              <div>
                <span
                  v-if="opportunity.description !== ''"
                  class="nuvola-text-helper"
                >
                  Chưa có mô tả
                </span>
              </div>
              <div>
                <span
                  v-if="opportunity.description !== ''"
                  class="nuvola-text-helper"
                >
                  Chưa có ghi chú
                </span>
              </div>
              <span class="nuvola-text-helper">{{ opportunity.description }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="shadow nuvola-border-radius-10 p-4">
          <h4>Chi tiết sản phẩm</h4>
          <a-divider></a-divider>
          <div class="row">
            <div class="col-4 col-xs-3 text-center d-flex align-items-center justify-content-center">
              <img
                v-if="opportunity.productItem && opportunity.productItem.productItemThumbnails && opportunity.productItem.productItemThumbnails[0]"
                :src="opportunity.productItem.productItemThumbnails[0].uri"
                style="height: 60px; max-width: 200px; height: auto"
                alt="Hình ảnh sản phẩm"
              >
            </div>
            <div class="col-8 col-xs-9">
              <span class="font-weight-bold nuvola-font-size-18 d-block">{{ opportunity.productItem.name }}</span>
              <span class="d-block">{{ opportunity.productItem.description }}</span>
              <span class="font-weight-bold">{{ opportunity.productItem.onRequest ? "Liên hệ" : formatCurrency(opportunity.productItem.price) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="shadow nuvola-border-radius-10 p-4">
          <h4>Khách hàng</h4>
          <a-divider></a-divider>
          <div>
            <h5>{{ opportunity.customer.name }}</h5>
            <div>
              <span class="font-weight-bold">{{ opportunity.customer.phoneNumber }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{ opportunity.customer.email }}</span>
            </div>
          </div>
          <div>
            <a-divider></a-divider>
            <div>
              <h5>Địa chỉ</h5>
              <div v-if="opportunity.opportunityAddress">
                <div class="d-flex align-items-center">
                  <a-icon type="home" class="mr-2" /> <span>{{ opportunity.opportunityAddress.streetNumber }} {{ opportunity.opportunityAddress.streetName }}, {{ opportunity.opportunityAddress.ward }}, {{ opportunity.opportunityAddress.district }}</span>
                </div>
                <div class="d-flex align-items-center">
                  <a-icon type="environment" class="mr-2" /> <span>{{ opportunity.opportunityAddress.city }}</span>
                </div>
              </div>
              <div v-else>
                <a-empty></a-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {store} from "@/store";
import {OPPORTUNITY_STATUSES} from "../../../constants/const";
import {OpportunityService} from "../../../store/services/api-service";
import {formatCurrency} from "../../../utils/common-utils"

export default {
  name: "OpportunityDetail",
  components: {
  },
  data() {
    return {
      OPPORTUNITY_STATUSES
    }
  },
  computed: {
    ...mapGetters([
      "opportunity"
    ]),
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.slug !== undefined) {
      await store.dispatch(
        "fetchOpportunity",
        to.params.slug
      );
    }
    return next();
  },
  methods: {
    async changeStatus(process) {
      if (this.opportunity.cancel) {
        this.$message.error('Không thể cập nhật! Đơn hàng tiềm năng đã bị hủy.');
        return;
      }
      await OpportunityService.updateStatus({
        id: this.opportunity.id,
        status: process.value
      }).then(() => {
        this.opportunity.status = process.value;
      }).catch(error => {
        console.log(error)
      })
    },
    async deleteOpportunity(opportunity) {
      this.loading = true;
      OpportunityService.deleteById(opportunity.id).then(() => {
        this.$store.dispatch("fetchOpportunities");
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    showConfirm: function(opportunity) {
      const that = this;
      this.$confirm({
        title: 'Bạn có chắc chắn xóa cơ hội này?',
        okType: 'danger',
        onOk() {
          that.deleteOpportunity(opportunity)
        }
      });
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    async updateOpportunityCancelStatus(cancelStatus) {
      await OpportunityService.updateOpportunityCancelStatus({
        id: this.opportunity.id,
        cancelStatus
      }).then(() => {
        this.opportunity.cancel = cancelStatus;
      }).catch(error => {
        console.log(error)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.opportunity-detail-wrapper {
  .category--thumbnail {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
</style>