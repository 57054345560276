<template>
  <div>
    <span ref="menuItemKey" class="d-none">idea</span>
    <!-- HEADER -->
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Danh sách bài viết
      </h1>
    </div>
    <!-- ADD BUTTON -->
    <div class="w-100 d-flex justify-content-end mb-4">
      <router-link
        to="create-idea"
      >
        <a-button
          icon="plus" type="primary"
          class="d-flex align-items-center"
        >
          Thêm bài viết
        </a-button>
      </router-link>
    </div>
    <a-table
      :columns="columns" :data-source="ideas"
      :row-key="category => category.id"
      size="middle"
      :loading="loading"
    >
      <div
        slot="thumbnail" slot-scope="text, idea"
        class="text-center"
      >
        <img
          :src="idea.thumbnail" alt="Idea thumbnail"
          style="max-width: 100px; height: auto; max-height: 50px"
        >
      </div>
      <div slot="title" slot-scope="text, idea">
        <div>
          <span
            v-if="idea" class="text-link-primary font-weight-bold nuvola-cursor-pointer"
            @click="navigateToEdit(idea.id)"
          >
            {{ idea.title }}
          </span>
        </div>
        <div>
          <span v-if="idea">{{ idea.createdAt | moment("DD/MM/YYYY") }}</span>
        </div>
      </div>
      <span slot="action" slot-scope="text, idea">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a-button
            shape="circle" icon="ellipsis"
            class="d-flex align-items-center justify-content-center"
          />
          <a-menu slot="overlay">
            <a-menu-item @click="navigateToEdit(idea.id)">
              Edit
            </a-menu-item>
            <a-menu-item @click="showConfirm(idea)">
              Delete
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {IdeaService} from "../../../store/services/api-service"

export default {
  name: "IdeaOverview",
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(
      [
        "ideas"
      ]
    ),
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: '',
          dataIndex: 'thumbnail',
          key: 'thumbnail',
          scopedSlots: {customRender: 'thumbnail'},
          width: '10%',
        },
        {
          title: 'Tiêu đề',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {customRender: 'title'},
          width: '30%',
        },
        {
          title: 'Mô tả ngắn',
          dataIndex: 'description',
          key: 'description',
          scopedSlots: {customRender: 'description'},
          width: '50%',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: '10%'
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchIdeaList");
    this.loading = false;
  },
  methods: {
    navigateToEdit(id) {
      this.$router.push({
        name: "editIdea",
        params: {
          slug: id
        }
      });
    },
    showConfirm: function (idea) {
      const that = this;
      this.$confirm({
        title: 'Bạn có chắc chắn xóa bài viết này?',
        okType: 'danger',
        onOk() {
          that.deleteIdea(idea)
        }
      });
    },
    deleteIdea(idea) {
      this.loading = true;
      IdeaService.deleteById(idea.id).then(() => {
        this.$message.success('Xóa bài viết thành công!');
        this.$store.dispatch("fetchIdeaList");
      }).catch(error => {
        this.$message.error('Có lỗi xảy ra!');
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  },
}
</script>

<style scoped>

</style>