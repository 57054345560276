<template>
  <div class="d-flex login-bg justify-content-center align-items-center nuvola-bg-blue p-4" style="min-height: 100vh">
    <div class="central-box p-4 rounded bg-white">
      <h1>Đăng nhập</h1>
      <a-form>
        <a-form-item
          class="mb-2"
          label="Tài khoản "
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$v.user.username.$error ? 'error' : ''"
          :help="$v.user.username.$error ? 'Vui lòng nhập tài khoản' : ''"
        >
          <a-input
            v-model="user.username"
            @input="$v.user.username.$touch()"
            @keyup.enter="handleSubmit"
          />
        </a-form-item>
        <a-form-item
          label="Mật khẩu"
          class="mb-2"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$v.user.password.$error ? 'error' : ''"
          :help="$v.user.password.$error ? 'Vui lòng nhập mật khẩu' : ''"
        >
          <a-input-password
            v-model="user.password"
            @input="$v.user.password.$touch()"
            @keyup.enter="handleSubmit"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button
            type="primary"
            :loading="loading"
            @click="handleSubmit"
          >
            Đăng nhập
          </a-button>
        </a-form-item>
      </a-form>
      <a-alert
        v-if="loginFail" message="Đăng nhập thất bại. Kiểm tra lại Tài khoản/Mật khẩu"
        type="error"
        closable
        @close="onCloseLoginFailAlert"
      />
    </div>
  </div>
</template>

<script>
import {ActionTypes} from "../../constants/action-types";
import required from "vuelidate/src/validators/required";
import {DEFAULT_LOGIN_REDIRECT_COMPONENT} from "../../constants/const";

export default {
  data() {
    return {
      username: "",
      user: {
        username: "",
        password: ""
      },
      loginFail: false,
      loading: false
    };
  },
  validations: {
    user: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({name: DEFAULT_LOGIN_REDIRECT_COMPONENT});
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.loginFail = false;
      this.$v.user.username.$touch();
      this.$v.user.password.$touch();
      if (this.$v.$invalid || this.$v.$error) {
        this.loading = false;
        return;
      }
      this.$store
        .dispatch(ActionTypes.LOGIN, this.user)
        .then(() => {
          this.loginFail = false;
          this.$router.push({name: DEFAULT_LOGIN_REDIRECT_COMPONENT});
          this.loading = false;
        }).catch(() => {
        this.loading = false;
        this.loginFail = true;
      });
    },
    onCloseLoginFailAlert(e) {
      this.loginFail = false;
    }
  }
};
</script>

<style scoped lang="scss">
  .central-box {
    margin-top: auto;
    margin-bottom: auto;
    width: 500px;
  }

  .login-bg {
    background-image: url("../../assets/images/login-background.jpg");
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
</style>
