<template>
  <div class="ctn-item top-products">
    <h3 class="text-center">
      Sản phẩm hàng đầu
    </h3>
    <div class="content">
      <div
        v-for="item in topCategories"
        :key="item.id"
        class="cat-item col-6 col-md-4 col-lg-3"
      >
        <div
          class="category--thumbnail nuvola-cursor-pointer"
          :style="{ 'background-image': 'url(' + item.thumbnail + ')' }"
          @click="navigateTo('category', item.id)"
        >
        </div>
        <div class="cat-title text-center">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ActionTypes} from "../../constants/action-types";

export default {
  data() {
    return {

    }
  },
  computed: {
    topCategories: function () {
      return this.$store.getters.topCategories
    }
  },
  created() {
    this.$store.dispatch(ActionTypes.LOAD_TOP_CATEGORIES)
  },
  methods: {
    async navigateTo(path, slug) {
      let loader = this.$loading.show();
      if (slug) {
        await this.$router.push(`/${path}/${slug}`).catch(error => {});
      } else {
        await this.$router.push(`/${path}`).catch(error => {});
      }
      loader.hide();
    },
  }
};
</script>

<style lang="scss" scoped>
.top-products {
  .cat-item {
    display: inline-block;
    .cat-title {
      margin-top: 10px;
      font-weight: bold;
      color: #000000;
    }
  }
  .category--thumbnail {
    width: 100%;
    padding-top: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}


</style>
