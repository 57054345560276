var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"30px"}},[_c('router-link',{attrs:{"to":"/admin/products"}},[_c('a-button',{staticClass:"d-flex justify-content-center mr-3",attrs:{"type":"primary","shape":"circle","icon":"left","size":"small"}})],1),_c('h1',{staticClass:"mb-0",staticStyle:{"font-size":"1.5rem"}},[_vm._v("\n      "+_vm._s(_vm.pageHeader)+"\n    ")])],1),_c('div',[_c('a-form',{attrs:{"form":_vm.productForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({staticClass:"mb-0",attrs:{"label":"Tên sản phẩm"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng nhập tên sản phẩm!',
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Vui lòng nhập tên sản phẩm!',\n                },\n              ],\n            },\n          ]"}],ref:"productName",attrs:{"placeholder":"Tên sản phẩm!"}})],1),_c('a-form-item',_vm._b({staticClass:"mb-0",attrs:{"label":"Mô tả ngắn"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng nhập mô tả!',
                } ],
            } ]),expression:"[\n            'description',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Vui lòng nhập mô tả!',\n                },\n              ],\n            },\n          ]"}],attrs:{"rows":"2","placeholder":"Mô tả ngắn về sản phẩm! (Chất liệu, bộ sưu tập...)"}})],1),_c('a-form-item',_vm._b({staticClass:"mb-0",attrs:{"label":"Danh mục"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'categoryId',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng chọn danh mục!'
                }
              ]
            } ]),expression:"[\n            'categoryId',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Vui lòng chọn danh mục!'\n                }\n              ]\n            },\n          ]"}],staticStyle:{"width":"50%"},attrs:{"placeholder":"Vui lòng chọn danh mục!"}},_vm._l((_vm.loadCategories),function(category){return _c('a-select-option',{key:category.id},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])}),1)],1),_c('a-form-item',_vm._b({staticClass:"mb-0",attrs:{"label":"Giá liên hệ"}},'a-form-item',_vm.formItemLayout,false),[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['onRequest', { valuePropName: 'checked' }]),expression:"['onRequest', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',_vm._b({staticClass:"mb-0",class:_vm.productForm.getFieldValue('onRequest') ? 'd-none' : '',attrs:{"label":"Giá"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'price',
            {
              rules: [ { validator: _vm.checkPriceValid } ],
            } ]),expression:"[\n            'price',\n            {\n              rules: [ { validator: checkPriceValid } ],\n            },\n          ]"}],ref:"productPrice",staticClass:"w-100",attrs:{"min":0,"step":0.1,"placeholder":"Giá sản phẩm!","formatter":function (value) { return ("₫ " + value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },"parser":function (value) { return value.replace(/\₫\s?|(,*)/g, ''); }}},[_c('a-icon',{attrs:{"slot":"suffix","type":"user"},slot:"suffix"})],1)],1),_c('a-input',{ref:"thumbnailLabel",staticClass:"d-none"}),_c('div',{staticClass:"offset-2 ant-form-item-label"},[_c('label',{staticClass:"ant-form-item-required",attrs:{"for":"span-hidden-2"}},[_vm._v("Ảnh mô tả")]),_c('span',{attrs:{"id":"span-hidden-2"}})]),_c('div',{staticClass:"clearfix offset-2"},[_c('a-upload',{attrs:{"list-type":"picture-card","file-list":_vm.fileList,"before-upload":_vm.beforeUpload},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 5)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n              Upload\n            ")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1),_c('div',{staticClass:"offset-2 my-4"},[_c('h5',[_vm._v("Chi tiết sản phẩm")])]),_vm._l((_vm.currentSpecifications),function(specification){return _c('a-form-item',_vm._b({key:specification.id,staticClass:"mb-0",attrs:{"label":specification.label}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            ("specificationContents[" + (specification.id) + "]")
          ]),expression:"[\n            `specificationContents[${specification.id}]`\n          ]"}],attrs:{"rows":"4","placeholder":specification.label}})],1)}),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saving}},[_vm._v("\n          Lưu sản phẩm\n        ")])],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }