<template>
  <div>
    <div class="d-flex align-items-center" style="margin-bottom: 30px">
      <h1 style="font-size: 1.5rem" class="mb-0">
        Danh sách loại phòng
      </h1>
    </div>
    <div class="w-100 d-flex justify-content-end mb-4">
      <router-link
        to="create-room"
      >
        <a-button
          icon="plus" type="primary"
          class="d-flex align-items-center"
        >
          Thêm loại phòng
        </a-button>
      </router-link>
    </div>

    <a-table
      :columns="columns" :data-source="rooms"
      :row-key="category => category.id"
      size="middle"
      :loading="loading"
    >
      <span slot="action" slot-scope="text, room">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a-button
            shape="circle" icon="ellipsis"
            class="d-flex align-items-center justify-content-center"
          />
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                @click="navigateToEdit(room.id)"
              >Edit</a>
            </a-menu-item>
            <a-menu-item @click="showConfirm(room.id)">
              Delete
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {RoomsService} from "../../../store/services/api-service";

  const columns = [
    {
      title: 'Loại phòng',
      dataIndex: 'name',
      key: 'name',
      width: '40%'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: {customRender: 'action'},
      width: '20%'
    }
  ];

  export default {
    name: "RoomOverview",
    data() {
      return {
        columns,
        loading: false
      }
    },
    computed: {
      ...mapGetters([
        "rooms"
      ])
    },
    async mounted() {
      this.loading = true;
      await this.$store.dispatch("loadRooms");
      this.loading = false;
    },
    methods: {
      navigateToEdit(id) {
        this.$router.push({
          name: "roomEdit",
          params : {
            slug: id
          }
        });
      },
      deleteRoom(id) {
        RoomsService.deleteById(id).then(() => {
          this.$store.dispatch("loadRooms");
        }).catch(error => {
          console.log(error);
        });
      },
      showConfirm: function(id) {
        const that = this;
        this.$confirm({
          title: 'Bạn có chắc chắn xóa loại phòng này?',
          okType: 'danger',
          onOk() {
            that.deleteRoom(id)
          }
        });
      },

    },
  }
</script>

<style scoped>

</style>