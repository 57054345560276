import Vue from "vue";
import Router from "vue-router";
import Login from "./views/admin/Login";
import MainPage from "./views/main/MainPage";
import Home from "./views/main/Home";
import About from "./views/main/About";
import Admin from "./views/admin/Admin";
import CategoryOverview from "./components/admin-page/category/CategoryOverview";
import {store} from "./store";
import {ActionTypes} from "./constants/action-types";
import CreateCategory from "./components/admin-page/category/CreateCategory";
import ErrorPage from "./components/shared/ErrorPage";
import ProductOverview from "./components/admin-page/product/ProductOverview";
import CreateProduct from "./components/admin-page/product/CreateProduct";
import RoomOverview from "./components/admin-page/room/RoomOverview";
import CreateRoom from "./components/admin-page/room/CreateRoom";
import CategoryDetails from "./views/main/CategoryDetails";
import ProductDetails from "./views/main/ProductDetails";
import RoomDetails from "./views/main/RoomDetails";
import TestSlider from "./components/main-page/ProductsSlider"
import SearchProducts from "./views/main/SearchProducts"
import OpportunityOverview from "./components/admin-page/opportunity/OpportunityOverview"
import CustomerOverview from "./components/admin-page/customer/CustomerOverview"
import CustomerDetail from "./components/admin-page/customer/CustomerDetail"
import OpportunityDetail from "./components/admin-page/opportunity/OpportunityDetail"
import ContactSettings from "./components/admin-page/organization-settings/ContactSettings"
import Dashboard from "./components/admin-page/dashboard/Dashboard"
import IdeaOverview from "./components/admin-page/idea/IdeaOverview"
import IdeaDetail from "./components/admin-page/idea/IdeaDetail"
import CommonSettings from "./components/admin-page/organization-settings/CommonSettings"
import {MENU_KEYS} from "./constants/admin-site-map"
import Ideas from "./views/main/Ideas"
import MainIdeaDetail from "./views/main/MainIdeaDetail"
import HomeSliderManager from "./components/admin-page/organization-settings/HomeSliderManager"
Vue.use(Router);

function checkPermissionToAccessRoute(menuKey) {
  return async (to, from, next) => {
    try {
      await store.dispatch(ActionTypes.CHECK_AUTH);
      const hasPermission = await store.getters['isAuthenticated'];
      if (hasPermission) {
        if (menuKey) {
          await store.dispatch("setAdminMenuKey", menuKey);
        }
        next()
      } else {
        // back to safety route
        next({name: "login"})
      }
    } catch (e) {
      // back to safety route
      next({name: "login"})
    }
  };
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: MainPage,
      children: [
        {
          path: "/",
          name: "home",
          component: Home
        },
        {
          path: "about",
          name: "about",
          component: About
        },
        {
          path: "category/:slug",
          name: "mainCategoryDetails",
          component: CategoryDetails
        },
        {
          path: "product/:slug",
          name: "mainProductDetails",
          component: ProductDetails
        },
        {
          path: "search-product",
          name: "searchProduct",
          component: SearchProducts
        },
        {
          path: "room/:slug",
          name: "mainRoomDetails",
          component: RoomDetails
        },
        {
          path: "ideas",
          name: "ideaList",
          component: Ideas
        },
        {
          path: "ideas/:slug",
          name: "mainIdeaDetail",
          component: MainIdeaDetail
        },
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/admin",
      component: Admin,
      beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.OPPORTUNITY),
      children: [
        {
          path: "",
          redirect: "opportunities"
        },
        {
          path: "categories",
          name: "categories",
          component: CategoryOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.CATEGORY),
        },
        {
          path: "create-category",
          name: "createCategory",
          component: CreateCategory,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.CATEGORY),
        },
        {
          path: "category/:slug",
          name: "categoryEdit",
          props: true,
          component: CreateCategory,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.CATEGORY),
        },
        {
          path: "products",
          name: "products",
          component: ProductOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.PRODUCT),
        },
        {
          path: "create-product",
          name: "createProduct",
          component: CreateProduct,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.PRODUCT)
        },
        {
          path: "product/:slug",
          name: "editProduct",
          component: CreateProduct,
          props: true,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.PRODUCT)
        },
        {
          path: "create-room",
          name: "createRoom",
          component: CreateRoom,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.ROOM),
        },
        {
          path: "rooms",
          name: "rooms",
          component: RoomOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.ROOM),
        },
        {
          path: "rooms/:slug",
          name: "roomEdit",
          props: true,
          component: CreateRoom,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.ROOM),
        },
        {
          path: "opportunities",
          name: "opportunityOverview",
          component: OpportunityOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.OPPORTUNITY),
        },
        {
          path: "customers",
          name: "customerOverview",
          component: CustomerOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.CUSTOMER),
        },
        {
          path: "customers/:slug",
          name: "customerDetail",
          component: CustomerDetail,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.CUSTOMER),
        },
        {
          path: "opportunities/:slug",
          name: "opportunityDetail",
          component: OpportunityDetail,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.OPPORTUNITY),
        },
        {
          path: "contact-settings",
          name: "contactSettings",
          component: ContactSettings,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.SETTINGS),
        },
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.DASHBOARD),
        },
        {
          path: "ideas",
          name: "ideas",
          component: IdeaOverview,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.IDEA),
        },
        {
          path: "create-idea",
          name: "createIdea",
          component: IdeaDetail,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.IDEA),
        },
        {
          path: "idea-detail",
          name: "editIdea",
          component: IdeaDetail,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.IDEA),
        },
        {
          path: "common-settings",
          name: "commonSettings",
          component: CommonSettings,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.SETTINGS),
        },
        {
          path: "home-slider-manager",
          name: "sliderManager",
          component: HomeSliderManager,
          beforeEnter: checkPermissionToAccessRoute(MENU_KEYS.SETTINGS),
        }
      ]
    },
    {
      path: '/404',
      name: 'NotFound',
      component: ErrorPage
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});
