import ApiService, {AuthService} from "../services/api-service";
import JwtService from "../services/jwt.service";
import {ActionTypes} from "../../constants/action-types";
import {MutationTypes} from "../../constants/mutation-types";
import {Path, UsersPath} from "../../constants/path";

const state = {
  errors: null,
  user: {},
  token: "",
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [ActionTypes.LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      AuthService.post(credentials)
        .then(({ data }) => {
          context.commit(MutationTypes.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(MutationTypes.SET_ERROR, response.data.errors);
        });
    });
  },
  [ActionTypes.LOGOUT](context) {
    context.commit(MutationTypes.PURGE_AUTH);
  },
  async [ActionTypes.CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      await ApiService.setHeader();
      await ApiService.get(UsersPath.CHECK_AUTH)
        .then(({ data }) => {
          context.commit(MutationTypes.SET_AUTH, data);
        })
        .catch((response) => {
          console.log(response);
          context.commit(MutationTypes.PURGE_AUTH);
          context.commit(MutationTypes.SET_ERROR, response.data.errors);
        });
    } else {
      await context.commit(MutationTypes.PURGE_AUTH);
    }
  },
};

const mutations = {
  [MutationTypes.SET_ERROR](state, error) {
    state.errors = error;
  },
  [MutationTypes.SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.userJson;
    state.token = data.token;
    state.errors = {};
    window.localStorage.setItem("user", JSON.stringify(data.userJson));
    JwtService.saveToken(state.token);
  },
  [MutationTypes.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    window.localStorage.removeItem("user");
    JwtService.destroyToken();
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
