<template>
  <div class="page-wrap">
    <h1 class="text-white">
      Error!
    </h1>
    <p class="errorContent">
      Có lỗi xảy ra. Vui lòng trở về trang chủ
    </p>
    <p><a @click="redirectTo('/')">Trang chủ</a></p>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  methods: {
    redirectTo(route) {
      this.$router.push(route);
    }
  }

}
</script>

<style scoped>
  /*body {*/
  /*background: #607D8B;*/
  /*font-family: 'Lato', serif;*/
  /*color: #fff;*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*}*/

  .page-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    text-align: center;
    background: #607D8B;
    color: #fff;
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Arial;
  }

  p, a {
    font-size: 1em;
  }

  h2, a {
    text-transform: uppercase;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    width: 120px;
    height: 44px;
    line-height: 44px;
    border-radius: 5px;
    border: 2px solid #fff;
    transition: all .5s ease;
  }

  a:hover {
    background: #90A4AE;
    border: 2px solid #90A4AE;
    width: 132px;
  }

  @media (min-width:576px) {
    h1 {font-size: 4em; }
    h2 {font-size: 2em; }
  }

  @media (min-width:768px) {
    h1 {font-size: 5em; }
    h2 {font-size: 3em; }
  }

  @media (min-width:992px) {
    h1 {font-size: 6em; }
    h2 {font-size: 4em; }
  }

  @media (min-width:1200px) {
    h1 {font-size: 7em; }
    h2 {font-size: 5em; }
  }

</style>