<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container">
      <div class="row w-100 m-0">
        <div class="col-md-7 col-12">
          <h1>{{ organizationSettings.contactTitle }}</h1>
          <div class="nuvola-paragraph" v-html="organizationSettings.contactContent"></div>
        </div>
        <div class="col-md-5 col-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.811397316968!2d108.17004078673052!3d16.075273946155864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314218e6c4a6ab4b%3A0xc2bc62226063b040!2zNDEgUGh1zIEgTMO0zKNjIDcsIFRoYW5oIEtow6ogVMOieSwgTGnDqm4gQ2hp4buDdSwgxJDDoCBO4bq1bmcsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1575821906275!5m2!1sen!2s"
            width="100%"
            height="450" frameborder="0"
            style="border:0;" allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {store} from "@/store";
import {mapGetters} from "vuex";

export default {
  name: "About",
  computed: {
    ...mapGetters([
      "organizationSettings"
    ])
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('fetchOrganizationSettings');
    return next();
  },
};
</script>
<style lang="scss">
.nuvola-paragraph {
  white-space: pre-line;
  text-align: justify;
  color: black;

  .image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>