<template>
  <div class="d-flex justify-content-center h-100">
    <div class="main-container">
      <h1 class="text-center">
        Ý tưởng
      </h1>
      <div class="w-100 m-0 d-flex flex-row flex-wrap">
        <div v-if="!ideas || ideas.length === 0" class="w-100 text-center">
          <a-empty class="text-center"></a-empty>
        </div>
        <div v-else class="d-block gallery">
          <div
            v-for="(idea, index) in ideas" :key="index"
            class="gallery-image mb-4"
          >
            <router-link :to="`ideas/${idea.id}`">
              <div class="d-inline-block shadow nuvola-cursor-pointer">
                <img
                  :src="idea.thumbnail" alt=""
                  class="mw-100"
                >
                <div class="p-4">
                  <h4>{{ idea.title }}</h4>
                  <span class="text-black">{{ idea.description }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Ideas",
  computed: {
    ...mapGetters(
      [
        "ideas"
      ]
    ),
  },
  async mounted() {
    let loader = this.$loading.show();
    this.scrollToTop();
    await this.$store.dispatch("fetchIdeaList");
    loader.hide();
  },
  methods: {
    scrollToTop() {
      this.$scrollTo("#top")
    }
  }
}
</script>

<style lang="scss">
  .ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.45) !important;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .gallery {
    column-gap: 20px;
    column-count: 1;
    @media only screen and (min-width: 576px) {
      column-count: 2;
    }
    @media only screen and (min-width: 960px) {
      column-count: 3;
    }
    .gallery-image {
      position: relative;
      margin: 0;
      padding: 0;
      &:hover, &:focus {
        transform: scale(1.02);
        transition: all .5s;
      }
      .gallery-image img {
        display: block;
        max-width: 100%;
        height: auto;
        border-style: none;
      }
    }
    figure {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 40px;
      margin-inline-end: 40px;
    }
  }
</style>