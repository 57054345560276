import {CustomerService} from "../services/api-service";
import {getStatusObject} from "../../utils/common-utils";
import Vue from "vue";

const state = {
  customers: [],
  customerTotal: 0,
  customer: {
    name: '',
    phoneNumber: '',
    opportunities: []
  }
};

const mutations = {
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setCustomerTotal(state, total) {
    state.customerTotal = total;
  },
  setCustomers(state, customers) {
    Vue.set(state, 'customers', customers);
  },
};

const actions = {
  async fetchCustomers(context, data) {
    await CustomerService.getList(data.page, data.query).then(res => {
      context.commit("setCustomers", res.data.customers);
      context.commit("setCustomerTotal", res.data.total);
    }).catch(error => {
      console.log(error);
      throw new Error(error);
    })
  },
  async fetchCustomer(context, slug) {
    await CustomerService.findById(slug).then(res => {
      context.commit("setCustomer", res.data);
    }).catch(error => {
      console.log(error);
      throw new Error(error);
    })
  },
  updateCustomer(context, payload) {
    context.commit("setCustomer", payload);
  }
};

const getters = {
  customers: state => {
    return state.customers;
  },
  customerTotal: state => {
    return state.customerTotal;
  },
  customer: state => {
    if (state.customer){
      state.customer.opportunities.map(item => {
        item.statusObject = getStatusObject(item.status);
        return item;
      });
    }
    return state.customer;
  }
};

export const customer = {
  state,
  mutations,
  actions,
  getters
};
