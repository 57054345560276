module.exports = {
  essentials: [
    {
      icon: require("@/assets/icons/delivery.jpg"),
      label: "Vận chuyển"
    },
    {
      icon: require("@/assets/icons/installation.jpg"),
      label: "Thi công"
    },
    {
      icon: require("@/assets/icons/return.jpg"),
      label: "Đổi trả"
    },
    {
      icon: require("@/assets/icons/planning.jpg"),
      label: "Thiết kế"
    },
    {
      icon: require("@/assets/icons/assembly.jpg"),
      label: "Lắp ráp"
    },
    {
      icon: require("@/assets/icons/assit.jpg"),
      label: "Hỗ trợ"
    }
  ]
};