<template>
  <div class="product--contact-form">
    <div class="product--order-button mt-2">
      <a-button @click="showModal">
        <a-icon type="shopping-cart" />
        Quan tâm sản phẩm
      </a-button>
    </div>
    <a-modal
      title="Thông tin liên lạc"
      :visible="customerContactFormVisible"
      :centered="true"
      @cancel="handleModalCancel"
    >
      <div class="customer-contact-form">
        <div class="product-summary">
          <h5>Thông tin sản phẩm</h5>
          <div class="row">
            <div class="col-4 col-xs-3 text-center d-flex align-items-center justify-content-center">
              <img
                v-if="product && product.productItemThumbnails && product.productItemThumbnails[0]"
                :src="product.productItemThumbnails[0].uri"
                style="height: 60px; max-width: 100%; height: auto"
                alt="Hình ảnh sản phẩm"
              >
            </div>
            <div class="col-8 col-xs-9">
              <span class="font-weight-bold nuvola-font-size-18 d-block">{{ product.name }}</span>
              <span class="d-block">{{ product.description }}</span>
              <span class="font-weight-bold">{{ product.onRequest ? "Liên hệ" : formatCurrency(product.price) }}</span>
            </div>
          </div>
        </div>
        <div class="modal-customer-form nuvola--mt-24">
          <div class="mb-2">
            <h5 class="m-0">
              Thông tin liên lạc
            </h5>
            <span>Để lại thông tin để chúng tôi có thể tư vấn tốt nhất về sản phẩm cho bạn.</span>
          </div>
          <a-form
            id="components-form-demo-normal-login"
            :form="contactForm"
            class="login-form"
            :hide-required-mark="true"
          >
            <a-form-item label="Họ tên">
              <a-input
                v-decorator="[
                  'fullName',
                  { rules: [{ required: true, message: 'Vui lòng nhập họ tên!' }] },
                ]"
                placeholder="Họ và tên"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="Số điện thoại">
              <a-input
                v-decorator="[
                  'phoneNumber',
                  { rules: [{ required: true, message: 'Vui lòng nhập số điện thoại!' }] },
                ]"
                placeholder="Số điện thoại"
              >
              </a-input>
            </a-form-item>
            <div class="d-flex align-items-center mb-2">
              <a-switch
                v-model="inputMoreInfo" class="mr-2"
                @change="onChangeSwitch"
              ></a-switch>
              <span class="text-color-ant-design">Nhập thêm thông tin địa chỉ</span>
            </div>

            <a-collapse
              v-model="additionalInfoActiveCollapseKey" :bordered="false"
            >
              <a-collapse-panel key="1" header="Nhập thêm thông tin địa chỉ">
                <a-form-item label="Email">
                  <a-input
                    v-decorator="[ 'email' ]"
                    placeholder="Email"
                  >
                  </a-input>
                </a-form-item>
                <span class="text-color-ant-design">Địa chỉ:</span>
                <div class="row">
                  <div class="col-8 pr-1 pb-0">
                    <a-form-item class="mb-0">
                      <a-input
                        v-decorator="[ 'streetName' ]"
                        placeholder="Tên đường"
                      >
                      </a-input>
                    </a-form-item>
                  </div>
                  <div class="col-4 pl-1 pb-0">
                    <a-form-item class="mb-0">
                      <a-input
                        v-decorator="[ 'streetNumber' ]"
                        placeholder="Số nhà"
                      >
                      </a-input>
                    </a-form-item>
                  </div>
                </div>
                <a-form-item class="mb-0">
                  <a-input
                    v-decorator="[ 'district' ]"
                    placeholder="Quận/huyện"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item class="mb-0">
                  <a-input
                    v-decorator="[ 'ward' ]"
                    placeholder="Phường/xã"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item class="mb-0">
                  <a-input
                    v-decorator="[ 'city' ]"
                    placeholder="Tỉnh/Thành phó"
                  >
                  </a-input>
                </a-form-item>
              </a-collapse-panel>
            </a-collapse>
          </a-form>
        </div>
      </div>
      <template slot="footer">
        <a-button key="cancel" @click="handleModalCancel">
          Hủy bỏ
        </a-button>
        <a-button
          key="submit" type="primary"
          :loading="confirmModalLoading" @click="handleModalOk"
        >
          Gửi thông tin
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {formatCurrency} from "../../utils/common-utils"
import {mapGetters} from "vuex";
import {OpportunityService} from "../../store/services/api-service";
import {
  NotificationTypes,
  CONTACT_FORM_INFO_KEY,
  INPUT_MORE_INFO_KEY,
  TRUE
} from "../../constants/const"

export default {
  name: "ProductContactFormModal",
  data() {
    return {
      customerContactFormVisible: false,
      confirmModalLoading: false,
      additionalInfoActiveCollapseKey: [],
      inputMoreInfo: false
    }
  },
  computed: {
    ...mapGetters(
      [
        "product",
      ]
    )
  },
  beforeCreate() {
    this.contactForm = this.$form.createForm(this, {name: 'customerContactForm'});
  },
  methods: {
    async showModal() {
      let contactFormInfo = window.sessionStorage.getItem(CONTACT_FORM_INFO_KEY);
      let inputMoreInfo;
      if (contactFormInfo) {
        inputMoreInfo = window.sessionStorage.getItem(INPUT_MORE_INFO_KEY);
        this.inputMoreInfo = inputMoreInfo === TRUE;
        this.additionalInfoActiveCollapseKey = this.inputMoreInfo ? ['1'] : [];
      }
      this.customerContactFormVisible = true;
      await this.contactForm.resetFields();

      if (contactFormInfo) {
        contactFormInfo = JSON.parse(contactFormInfo);
        this.contactForm.setFieldsValue({
          fullName: contactFormInfo.customer.name,
          phoneNumber: contactFormInfo.customer.phoneNumber
        });
        if (this.inputMoreInfo) {
          let customerAddress = contactFormInfo.customer.customerAddress;
          this.contactForm.setFieldsValue({
            email: contactFormInfo.customer.email,
            streetName: customerAddress.streetName,
            streetNumber: customerAddress.streetNumber,
            district: customerAddress.district,
            ward: customerAddress.ward,
            city: customerAddress.city
          })
        }
      }
    },
    handleModalOk(e) {
      this.contactForm.validateFields((err, values) => {
        if (err) {
          // validation failed
          return;
        }
        this.confirmModalLoading = true;
        let contactFormInfo = {
          productId: this.product.id,
          customer: {
            name: values.fullName,
            phoneNumber: values.phoneNumber
          }
        };
        if (this.inputMoreInfo) {
          contactFormInfo.customer.email = values.email;
          contactFormInfo.customer.customerAddress = {
            streetName: values.streetName,
            streetNumber: values.streetNumber,
            district: values.district,
            ward: values.ward,
            city: values.city
          }
        }
        OpportunityService.create(contactFormInfo).then(() => {
          window.sessionStorage.setItem(CONTACT_FORM_INFO_KEY, JSON.stringify(contactFormInfo));
          window.sessionStorage.setItem(INPUT_MORE_INFO_KEY, this.inputMoreInfo);
          this.$notification[NotificationTypes.SUCCESS]({
            message: 'Đã nhận được thông tin của bạn',
            description: 'Chúng tôi sẽ liên lạc tới bạn sớm nhất có thể để tư vấn tốt nhất về sản phẩm của chúng tôi cho bạn!',
            duration: 8,
          });
        }).catch(error => {
          this.$message.error(error);
          console.log(error);
        }).finally(() => {
          this.confirmModalLoading = false;
          this.handleModalCancel();
        });
      });
    },
    handleModalCancel() {
      this.inputMoreInfo = false;
      this.additionalInfoActiveCollapseKey = [];
      this.customerContactFormVisible = false;
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    onChangeSwitch() {
      this.additionalInfoActiveCollapseKey = this.inputMoreInfo ? ['1'] : []
    },
  }
}
</script>

<style lang="scss">
  .modal-customer-form {
    .ant-form-item-label {
      line-height: 1.5;
    }

    .ant-form-item {
      margin-bottom: 0.875rem;
    }

    .ant-collapse-item {
      border-bottom: 0 !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-header {
      display: none;
    }

    label {
      margin-bottom: 0;
    }
  }
</style>